import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

var config = {
    apiKey: "AIzaSyCAMLv26LPJbYvVi56rfDky3qbRNNzR-Qw",
    authDomain: "unobiweb-v1.firebaseapp.com",
    projectId: "unobiweb-v1",
    storageBucket: "unobiweb-v1.appspot.com",
    messagingSenderId: "705609758214",
    appId: "1:705609758214:web:9a99bc195ed4d29effb587",
    measurementId: "G-LSMTPTJZ9Q"
  };
  var app = firebase.initializeApp(config);
  const db = firebase.firestore();
  const auth = firebase.auth();
  
  export default db;
