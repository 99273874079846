import React from 'react';
import './App.css';


// React router dome 
// import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// Pages Imports 
import Home from './pages/home/Home'
import WhatWeProvide from './pages/what we provide/WhatWeProvide';
import AboutUs from './pages/about us/AboutUs'
import ContactUs from './pages/contact us/Contact_Us'
import EstimatePage from './components/estimate page/EstimatePage';
import PageNotFound from './pages/404/PageNotFound';
import BlogPage from './pages/blog/BlogPage';
import BlogDetails from './pages/BlogDetails/BlogDetails';
import MobileAppDevelopment from './pages/mobile app development/MobileAppDevelopment';
import WebAppDevelopment from './pages/web app development/WebAppDevelopment';
import DesktopAppDevelopment from './pages/desktop app development/DesktopAppDevelopment';
import RoboticProcessAutomation from './pages/robotic process automation/RoboticProcessAutomation';
import UiUxDesigning from './pages/ui and ux design/UiUxDesigning';
import GraphicDesigning from './pages/graphic designing/GraphicDesigning';
import TalentSourcing from './pages/talent sourcing/TalentSourcing';
import Support from './pages/support/Support';
import Maintenance from './pages/maintenance/Maintenance';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import OurWorksPage from './pages/OurWorksPage/OurWorksPage';
import SeoPage from './seo/SeoPage';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<WhatWeProvide />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/estimate" element={<EstimatePage />} />
          <Route path="/blogs" element={<BlogPage />} /> 
          <Route path="/talent-sourcing" element={<TalentSourcing />} /> 
          <Route path="/support" element={<Support />} /> 
          <Route path="/maintenance" element={<Maintenance />} /> 
          <Route path="/mobile-app-development" element={<MobileAppDevelopment />} />    
          <Route path="/web-app-development" element={<WebAppDevelopment />} />    
          <Route path="/desktop-app-development" element={<DesktopAppDevelopment />} />     
          <Route path="/robotic-process-automation" element={<RoboticProcessAutomation />} />          
          <Route path="/ui-ux-designing" element={<UiUxDesigning />} />  
          <Route path="/graphic-designing" element={<GraphicDesigning />} />  
          <Route path='/portfolio' element={<PortfolioPage />}></Route>
          <Route path="/:platform/:service" element={<OurWorksPage />} />                              
          <Route path="/blog-details/:title" element={<BlogDetails />} />                    
          <Route path="/:url" element={<SeoPage />} />                              
          <Route path="*" element={<PageNotFound />} />                                    
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
