import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'
import PortfolioPageData from "../../Data/PortfolioPageData";
import { useParams } from "react-router-dom";
import './OurWorksPage.css';

const OurWorksPage = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  let parameters = useParams();
  let service =  parameters.service;
  let [data, setData] = useState();
  let datas = PortfolioPageData.find(find => find.params === service);
  let banner = datas['banner'];
  let content = datas['content'];
  let link = datas['sitelink'];
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('About - In our FlutterFarm, there are no Herds! We have only Nerds!');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Our works</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5">
        <div className="container transform_bg">
            <div className="row">
            <div className="col-12 text-center">
                <img src={banner} class="widthFull"></img>
            </div>
            </div>
        </div>
      </section>
      <section className="pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <h3 className="content-heading">Details</h3>
              <p className="content-desc">
              {content}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 content-desc paddingLeft">
              <p>
                Company Name<br></br>
                Refresh tech labs
              </p>
              <p>
                link<br></br>
                <a href={link} target="_blank">{link}</a>
              </p>              
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5">
        <Transform />
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default OurWorksPage;
