import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./WebAppDevelopment.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const WebAppDevelopment = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – web app development');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Web App Development</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>



      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/web_development.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/web_development.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              Web app development is the process of creating a web application. A web application is a software application that is run over the internet. It is different from a traditional desktop application, which is installed on a user’s computer and accessed through the user interface.


Web app development can be done using a variety of programming languages and frameworks. The most popular programming languages for web app development are JavaScript, PHP, and Python. The most popular frameworks are Laravel, Ruby on Rails, and Express.

</p>

            </div>
          </div>
        </div>
      </section>

      <center><h3 >
The process of web app development typically includes the following steps:
</h3></center>
 <div className="container01">
  <div className="card02">
    <div className="face face1">
    <div className="content">
      <span className="stars"></span>
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Planning</h5>
      <p className="java"  style={{"color":"white"}}>The first step in web app development is to plan the application. This includes defining the goals of the application, the target audience, the features that the application will include, and the budget.</p>
    </div>
  </div>
  <div className="face face2">
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Planning</h5>
  </div>
 </div>
 <div className="card02">
    <div className="face face1">
    <div className="content">
      <span className="stars"></span>
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Design</h5>
      <p className="java"  style={{"color":"white"}}> The next step is to design the application. This includes creating wireframes and prototypes to define the user interface and user experience.</p>
    </div>
  </div>
  <div className="face face2">
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Design</h5>
  </div>
</div>
<div className="card02">
    <div className="face face1">
    <div className="content">
      <span className="stars"></span>
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Development</h5>
      <p className="java"  style={{"color":"white"}}>Once the design is complete, the next step is to start development. This includes writing the code for the application and setting up the hosting environment.</p>
    </div>
  </div>
  <div className="face face2">
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Development</h5>
  </div>
</div>


</div>
   
<div className="container01">
<div className="card02">
    <div className="face face1">
    <div className="content">
      <span className="stars"></span>
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Testing</h5>
      <p className="java"  style={{"color":"white"}}> After the application is developed, it needs to be tested to ensure that it works as expected. This includes both user testing and technical testing.</p>
    </div>
  </div>
  <div className="face face2">
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Testing</h5>
  </div>
 </div>
 <div className="card02">
    <div className="face face1">
    <div className="content">
      <span className="stars"></span>
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Deployment</h5>
      <p className="java"  style={{"color":"white"}}> Once the application is tested and ready to go, it needs to be deployed to a web server. This can be done using a variety of methods, such as FTP, SSH, or a web hosting control panel.</p>
    </div>
  </div>
  <div className="face face2">
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Deployment</h5>
  </div>
</div>
<div className="card02">
    <div className="face face1">
    <div className="content">
      <span className="stars"></span>
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Maintenance</h5>
      <p className="java"  style={{"color":"white"}}>Even after the application is deployed, it will need to be maintained. This includes fixing bugs, adding new features, and keeping the application up-to-date with the latest security patches.</p>
    </div>
  </div>
  <div className="face face2">
      <h5 className="java" style={{"color":"white","font-weight":"700"}}>Maintenance</h5>
  </div>
</div>

</div>

<center style={{"padding-top" : "2rem"}}><h3 >
The Mockups of our Web development:
</h3></center>

<div className="container01">
  
  <center><img src="/assets/img/flutterfarm_web_1.png" style={{"height":"225px"}} /></center>
  
  <center><img src="/assets/img/flutterfarm_web_2.png" style={{"height":"225px"}} /></center>
  
  <center><img src="/assets/img/flutterfarm_web_3.png" style={{"height":"225px"}} /></center>


</div>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default WebAppDevelopment;
