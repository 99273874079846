import React, { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import "./BlogPage.css";
import db from "../../components/Firebase";
import Footer from "../../components/footer/Footer";
import Transform from "../../components/transform/Transform";
import Navbar from "../../components/navbar/Navbar";
import useDocumentTitle from '../../useDocumentTitle'

function BlogPage() {
  const routePath = useLocation();
  let [blogs, setBlogs] = useState([]);
  let [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState("");  
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  const fetchBlogs = async () => {
    setLoading("true");
    await db.collection("flutterfarm_blogs").orderBy("b_timestamp",'desc').get().then((querySnapshot) => {      
        if(querySnapshot.empty){
            setEmpty(true);
        }   
        else{
            querySnapshot.forEach(element => {
                setEmpty(false);                    
                var data = element.data();
                setBlogs(arr => [...arr , data]);                            
            });      
        }    
    });
    setLoading("");
}       
  useEffect(() => {
    onTop();
    setBlogs([]);
    fetchBlogs();
  }, [routePath]);
  const blogDetails = (title) => {
    let replace1 = title.replace(/ /gi,'-');            
    let link = '../blog-details/' + replace1;    
    window.location.href = link;    
  }
  useDocumentTitle('FlutterFarm - Blogs');

  return (
    <React.Fragment>
      <Navbar />
      <section className="blog">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="blog_head">Blogs</p>
            </div>
          </div>
        </div>
      </section>      
      <section className="services">
        <div className="container">
          <div className="">
          {loading === "true" ? 
            (
              <div className="row">
                <div className="col-12 text-center pt-5">
                  <div
                    className="spinner-grow text-center mx-auto"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="visually-hidden">
                      Loading...
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mb-4">
              {
                  empty ? (
                      <center><h3>No Blogs Found</h3></center>
                  ) :(
                      blogs && blogs.map(blog=>{
                          let timestamp_int_convert = parseFloat(blog.b_timestamp);
                          let date = new Date(timestamp_int_convert*1000);
                          var date_creation = date.toLocaleDateString("en-US");                        
                          return(                
                              <div className="col-md-6 col-lg-4 mt-5" onClick={() => blogDetails(blog.b_title)}>
                                  <div className="card-body">
                                      <img
                                      src={"data:image/jpeg;base64," + blog.b_image}
                                      className="img-fluid py-4"
                                      alt="flutterfarm" loading="crazy"
                                      />
                                      <p className="blog-category text-left">
                                          <a href="#" style={{"textDecoration":"none","color":"#b4c3be"}}>{date_creation}</a>
                                      </p>                        
                                      <h1 style={{"fontSize":"15px"}}>{blog.b_title}
                                      </h1>
  
                                      {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                                  </div>
                              </div>          
                          )
                      })    
                  )
              }    
              </div>  
            ) 
          }               
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

export default BlogPage;
