import React, { useEffect } from "react";
import { useLocation,NavLink } from "react-router-dom";

import "./WhatWeProvide.css";
import Footer from "../../components/footer/Footer";
import Transform from "../../components/transform/Transform";
import Navbar from "../../components/navbar/Navbar";
import useDocumentTitle from '../../useDocumentTitle'

function WhatWeProvide() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – Web and Mobile App development, RPA and more Services.');

  return (
    <React.Fragment>
      <Navbar />

      <section className="services">
        <div className="container py-5">
          <div className="row pb-4">
            <div className="col-12 text-center">
              <p className="services_ts">Services We Offer</p>
              {/* <p className='services_subtext_ts'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p> */}
            </div>
          </div>
          <div className="">
            <div className="row mb-4">

             <div className="col-md-6 col-lg-4">
             <NavLink to="/mobile-app-development" style={{"text-decoration":"none"}}><div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Mobile App development</p>
                    <img
                      src="./assets/img/what_we_provide/mobile_app_development.svg"
                      className="img-fluid py-4"
                      alt="mobile app development" loading="crazy"
                    />
                    <p className="service_card_text">
                      Whether it is iOS or Android, we got you covered. With
                      Flutter framework, we offer you the same code base for
                      both operating system.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div> 

              <div className="col-md-6 col-lg-4 mt-5 mt-md-0">
              <NavLink to="/web-app-development" style={{"text-decoration":"none"}}> 
                <div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Web App development</p>
                    <img
                      src="./assets/img/what_we_provide/webAppDevelopment.svg"
                      className="img-fluid py-4"
                      alt="web app development" loading="crazy"
                    />
                    <p className="service_card_text">
                      We prefer Flutter framework, so you have same code base
                      for all platforms, but we also offer ReactJS, NodeJs & PHP
                      support for your web app development.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5 mt-lg-0">
              <NavLink to="/desktop-app-development" style={{"text-decoration":"none"}}> <div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Desktop App development</p>
                    <img
                      src="./assets/img/what_we_provide/desktop_app_development.svg"
                      className="img-fluid py-4"
                      alt="desktop app development" loading="crazy"
                    />
                    <p className="service_card_text">
                      When you sign up for Mobile App development, get the
                      desktop app for absolutely free if there are no UX/UI
                      changes.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5">
              <NavLink to="/robotic-process-automation" style={{"text-decoration":"none"}}> <div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">
                      Robotic Process Automation (RPA)
                      {/* <br />
                                        <span className='rpa_ts'>Robotic process automation </span>  */}
                    </p>
                    <img
                      src="./assets/img/what_we_provide/rpa.svg"
                      className="img-fluid py-4"
                      width={60}
                      alt="rpa" loading="crazy"
                    />
                    <p className="service_card_text">
                      With Automation Anywhere, we provide you all your needs
                      for automation. Quick turnaround of the automation
                      projects to save you ton of money.
                    </p>

                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5">
              <NavLink to="/ui-ux-designing" style={{"text-decoration":"none"}}><div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">UI/UX Design</p>
                    <img
                      src="./assets/img/what_we_provide/uiux.svg"
                      className="img-fluid py-4"
                      alt="ui/ux" loading="crazy"
                    />
                    <p className="service_card_text">
                      We have world class experts to provide you UI/UX with
                      strong fundamentals on design. Try us out to find more.
                    </p>

                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5">
              <NavLink to="/graphic-designing" style={{"text-decoration":"none"}}><div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Graphics designing</p>
                    <img
                      src="./assets/img/what_we_provide/graphicdesign.svg"
                      className="img-fluid py-4"
                      alt="graphics design" loading="crazy"
                    />
                    <p className="service_card_text">
                      Designing logos, brochures or social media posts? Just
                      call us for a better priced design with faster delivery
                      with our experts.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5">
              <NavLink to="/talent-sourcing" style={{"text-decoration":"none"}}><div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Talent Sourcing</p>
                    <img
                      src="./assets/img/what_we_provide/Talent_sourcing.svg"
                      className="img-fluid py-4"
                      alt="talent sourcing" loading="crazy"
                    />
                    <p className="service_card_text">
                      Do you want to try out few ideas with needed talents? We
                      offer you contracts that goes for{" "}
                      <span className="numbers">3, 6 or 12</span> months for any
                      number of experts at the very best cost. Call us and know
                      more.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5">
              <NavLink to="/support" style={{"text-decoration":"none"}}><div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Support</p>
                    <img
                      src="./assets/img/what_we_provide/support.svg"
                      className="img-fluid py-4"
                      alt="support" loading="crazy"
                    />
                    <p className="service_card_text">
                      We guarantee you a reply within 24 hours, whether it’s a
                      sales call or technical call. When we do our projects, we
                      will have dedicated support and daily updates comes to
                      your email. Check us out by offering small projects.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
              <div className="col-md-6 col-lg-4 mt-5">
              <NavLink to="/maintenance" style={{"text-decoration":"none"}}><div
                  className="card service_card_mod"
                  style={{ height: "20.5rem" }}
                >
                  <div className="card-body text-center">
                    <p className="service_card_head">Maintenance</p>
                    <img
                      src="./assets/img/what_we_provide/maintain.svg"
                      className="img-fluid py-4"
                      alt="maintanance" loading="crazy"
                    />
                    <p className="service_card_text">
                      You are our partner even after the project is over, we
                      continue to provide maintenance at our best costs and keep
                      our relationship forever.
                    </p>
                    {/* <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a> */}
                  </div>
                </div></NavLink>
              </div>
            </div>
          </div>

          {/* <div className="show_below_md">                
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card service_card_mod mt-3 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>Mobile App Development</p>
                                    <img src="./assets/img/what_we_provide/mobile_app_development.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>Web App Development</p>
                                    <img src="./assets/img/what_we_provide/webAppDevelopment.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>                   
                    </div>
                    <div className="row mt-md-5">                        
                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>Desktop App Development</p>
                                    <img src="./assets/img/what_we_provide/desktop_app_development.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div> 

                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>
                                        RPA 
                                    </p>
                                    <img src="./assets/img/what_we_provide/rpa.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>                                            
                    </div>
                    <div className="row mt-md-5">
                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>UI/UX Design</p>
                                    <img src="./assets/img/what_we_provide/uiux.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>Support and Maintanance</p>
                                    <img src="./assets/img/what_we_provide/support.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="row mt-md-5">
                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>Talent Sourcing</p>
                                    <img src="./assets/img/what_we_provide/Talent_sourcing.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card service_card_mod mt-5 mt-md-0 mx-auto" style={{"width":"19rem"}}>
                                <div className="card-body text-center">
                                    <p className='service_card_head'>Graphics Designing</p>
                                    <img src="./assets/img/what_we_provide/graphicdesign.svg" className='img-fluid py-4' alt="" />
                                    <p className='service_card_text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor</p>
                                    <a className="btn service_btn_ts"> Know More <i className="fa-solid fa-right-long ps-1"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        </div>
      </section>

      <section className="why-flutterfarm mb-5">
        <div className="container-fluid container-lg pt-4">
          <div className="row">
            <div className="col-12 text-center">
              <p className="why-flutterfarm_head why_text_hide_top">
                Why Choose FlutterFarm ?
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-5 col-lg-4 mt-md-auto mt-lg-0 text-lg-center">
              <img
                src="./assets/img/what_we_provide/why_flutter_farm.webp"
                className="img-fluid hide_WhyFlutterFarm"
                width={300}
                alt="Why Choose FlutterFarm" loading="crazy"
              />
            </div>
            <div className="col-md-7 col-lg-8 my-auto">
              <p className="why-flutterfarm_head why_text_hide text-center text-md-start">
                Why Choose FlutterFarm ?
              </p>
              <p className="why-flutterfarm_ts px-3 px-lg-0 pt-2">
                Development is expensive. Multiple platforms makes it even
                harder. More expensive. More time for deployment. More buy fixes.
                More maintainence. We solve it by easily managable code
                structure that can be deployed to any of the following platforms.
                <div className="row" style={{ marginLeft: "-30px" }}>
                  <div className="col-4">
                    <ul className="list-group">
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/ios.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="macos" loading="crazy"
                          />
                        </span>
                        <span>MacOs</span>
                      </li>
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/windowsos.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="windows" loading="crazy"
                          />
                        </span>
                        <span>Windows</span>
                      </li>
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/linux.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="linux" loading="crazy"
                          />
                        </span>
                        <span>Linux</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-4">
                    <ul className="list-group">
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/chrome.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="chrome" loading="crazy"
                          />
                        </span>
                        <span>Chrome</span>
                      </li>
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/edge.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="edge" loading="crazy"
                          />
                        </span>
                        <span>Edge</span>
                      </li>
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/firefox.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="firefox" loading="crazy"
                          />
                        </span>
                        <span>Firefox</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-4">
                    <ul className="list-group">
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/android_icon.svg"
                            width={25}
                            className="img-fluid me-3"
                            alt="andriod" loading="crazy"
                          />
                        </span>
                        <span>Android</span>
                      </li>
                      <li className="list-group-item mt-lg-3">
                        <span>
                          <img
                            src="./assets/img/what_we_provide/ios.svg"
                            width={20}
                            className="img-fluid me-3"
                            alt="ios" loading="crazy"
                          />
                        </span>
                        <span>IOS</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='testimonial pb-5'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <p className='services_ts'>What Our Clients Says</p>
                        <p className='services_subtext_ts'>Our clients sends bunch of love with our service and we always love to work with them</p>
                    </div>
                </div>

                <div className="row g-0 py-4">
                    <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="row">
                            <div className="col-2 my-auto">
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                    <img src="./assets/img/what_we_provide/left_arrow_bg.svg" className='img-fluid' width={50} alt="" />
                                    <span className="visually-hidden">Previous</span>
                                </button>
                            </div>
                            <div className="col-8 test_carousel_bg">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="10000" style={{"height":"18rem"}}>
                                        <div className="row p-3 g-0">
                                            <div className="col-2 my-auto">
                                                <img src="https://cdn-dppbk.nitrocdn.com/yQqYxSaTIazRSTSDbfxPrqFzJPJOhsTG/assets/static/optimized/rev-aa89c8d/wp-content/uploads/2019/08/Max-Zschoch-72x72.png" className='img-fluid rounded-circle' width={100} alt="" />
                                                <p className='client_name pt-3 ps-2'>HariRam</p>
                                                <p className='client_company ps-3'>Unobi</p>
                                            </div>
                                            <div className="col-10">
                                                <p className='testi_ts'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco laboris nisi ut aliquip ex ea commodo consequat mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco laboris nisi ut aliquip ex ea commodo consequat..Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco </p>
                                            </div>
                                        </div>                                           
                                    </div>
                                        <div className="carousel-item" data-bs-interval="2000" style={{"height":"18rem"}}>
                                        <img src="..." className="d-block w-100" alt="..." />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Second slide label</h5>
                                            <p>Some representative placeholder content for the second slide.</p>
                                        </div>
                                        </div>
                                        <div className="carousel-item" style={{"height":"18rem"}}>
                                        <img src="..." className="d-block w-100" alt="..." />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Third slide label</h5>
                                            <p>Some representative placeholder content for the third slide.</p>
                                        </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="col-2 my-auto">
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                    <img src="./assets/img/what_we_provide/Right_arrow_bg.svg" className='img-fluid' width={50}  alt="" />
                                    <span className="visually-hidden">Next</span>
                                </button>
                                </div>
                            </div>     
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="carousel-indicators" style={{"opacity":"0.2"}}>
                                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                </div>
                            </div>                                              
                        </div>
                </div>
            </div>
        </section> */}

      <Transform />
      <Footer />
    </React.Fragment>
  );
}

export default WhatWeProvide;
