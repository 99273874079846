import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./TalentSourcing.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const TalentSourcing = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – talent sourcing');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Talent Sourcing</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/Talent_sourcing.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              Do you want to try out few ideas with needed talents? We offer you contracts that goes for 3,6,12 months for any number of experts at the very best cost. Call us and know more. 
</p>              <p className="nerds_text pt-2">
Talent Management We work closely with you to build a talent management program that meets your business needs. We offer a complete set of tools and services for all phases of your talent management program. 
</p>              <p className="nerds_text pt-2">
Talent Development We help you in becoming a leading talent development organization by providing innovative learning solutions. This will help you to find the best talent for your company.
</p>              <p className="nerds_text pt-2">

 Lead and manage the recruitment process including job board postings, candidate screening, and interviewing for a variety of positions throughout the company.
 </p>              <p className="nerds_text pt-2">

 Partner with hiring managers to ensure an efficient recruiting process.

              </p>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/Talent_sourcing.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

   
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default TalentSourcing;
