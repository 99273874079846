function PortfolioTabs({filterCategory, tabsData}){
    return(
      <>
        <div className="row my-4">
            {
                tabsData.map((category, index)=>{
                return (
                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
                        <button className="btn btn-outline-primary mx-2 text-capitalize tab-style" onClick={()=> filterCategory(category)} key={index}>{category}</button>
                    </div>
                )
                })
            }
        </div>
      </>
    )
}

export default PortfolioTabs;