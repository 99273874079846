import React from "react";
import "./Estimate.css";

import { Link } from "react-router-dom";

const estimate = ({ estimate, btn, icon }) => {
  return (
    <React.Fragment>
      <Link
        to="/estimate"
        type="button"
        className={`${btn} my-auto  btn-blue_mod text-white`}
      >
        {estimate}
        <i className={`${icon}`}></i>
      </Link>
    </React.Fragment>
  );
};

export default estimate;
