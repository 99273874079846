import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./GraphicDesigning.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const GraphicDesigning = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – graphic designing');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Graphic Designing</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/graphic_designing.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/graphic_designing.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              Graphic design is a form of communication that uses images, words, and/or graphics to convey a message or visual information. Graphic designers use a combination of typography, visual arts, and page layout techniques to create visual compositions, typically for print or electronic media. 
</p>              <p className="nerds_text pt-2">
The term “graphic design” can refer to a number of different creative and professional disciplines that focus on visual communication and presentation. Graphic designers, for example, create visual concepts that inspire, inform, and transform. They develop the overall layout and production design for advertisements, brochures, magazines, and corporate reports. 
</p>              <p className="nerds_text pt-2">
Web designers, on the other hand, focus on creating the look and feel of websites. They work with web developers to create the website’s overall layout and design, as well as its individual pages and features. 
</p>              <p className="nerds_text pt-2">
User experience (UX) designers and user interface (UI) designers are concerned with how a website or app looks and functions from the user’s perspective. They design user-friendly interfaces and features that make it easy for people to navigate websites and apps. 
</p>              <p className="nerds_text pt-2">
Graphic designers use a variety of design elements to create visual compositions. These elements include color, typography, imagery, and space. They use these elements to communicate a message or achieve a specific visual effect. 
Color is one of the most important elements of graphic design. Color can be used to create moods, convey messages, and draw attention to certain elements. 
Typography is the art and technique of arranging type to create a desired effect. Typefaces are the different styles of letters, numbers, and symbols used in a composition. 
</p>              <p className="nerds_text pt-2">
Imagery includes all the visual elements used in a composition, such as photos, illustrations, and charts. 
</p>              <p className="nerds_text pt-2">
Space is the area around and between the different elements in a composition. It can be used to create balance, contrast, and hierarchy.

              </p>
            </div>
          </div>
        </div>
      </section>

    
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default GraphicDesigning;
