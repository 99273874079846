import React from "react";
import Estimate from "../estimate/Estimate";
import "./Transform.css";

const transform = () => {
  return (
    <React.Fragment>
      <div className="container transform_bg py-5">
        <div className="row">
          <div className="col-12 text-center">
            <p className="transform_head pb-4">Lets transform your business</p>
            <Estimate
              btn="btn btn-lg"
              estimate="Estimate Project"
              icon="fa-solid fa-right-long ps-3 py-2"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default transform;
