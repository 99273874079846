import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./UiUxDesigning.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const UiUxDesigning = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – ui/ux designing');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Ui / Ux Designing</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/ui-ux.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              Ui / Ux design is the process of designing user interfaces for digital products such as websites and mobile apps. It involves creating user flows, wireframes, and prototypes to help guide developers in building the final product. The goal of Ui / Ux design is to create a product that is both easy to use and visually appealing.
</p>              <p className="nerds_text pt-2">



Some benefits of Ui / Ux design include:
</p>              <p className="nerds_text pt-2">



1. Improving the user experience: By making products easier to use, Ui / Ux design can improve the overall user experience and make users more likely to return to the product in the future.
</p>              <p className="nerds_text pt-2">



2. Increasing conversion rates: A well-designed product is more likely to convert users into customers or leads.
</p>              <p className="nerds_text pt-2">



3. Facilitating engagement: Good Ui / Ux design can help keep users engaged with a product by making it more visually appealing and easy to use.
</p>              <p className="nerds_text pt-2">



4. Creating brand loyalty: Users who have a positive experience with a product are more likely to be loyal to the brand and recommend the product to others.
</p>              <p className="nerds_text pt-2">



5. Enhancing customer satisfaction: Ultimately, the goal of Ui / Ux design is to create a product that customers are happy with. By making products easier to use and more visually appealing, Ui / Ux design can help increase customer satisfaction.

              </p>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/ui-ux.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

    
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default UiUxDesigning;
