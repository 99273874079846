import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./RoboticProcessAutomation.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const RoboticProcessAutomation = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – robotic process automation');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Robotic Process Automation (RPA)</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/rpa.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/rpa.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              What is robotic process automation?
</p>              <p className="nerds_text pt-2">              
 Robotic process automation (RPA) is a technology that allows organizations to automate repetitive, rules-based human tasks. RPA is typically used to automate tasks that are performed by employees within business processes, such as data entry, claims processing, and compliance checking.
 </p>              <p className="nerds_text pt-2">
What are the business benefits of RPA? 
</p>              <p className="nerds_text pt-2">
There are many benefits to implementing RPA within a business, including increased efficiency and accuracy, reduced costs, and improved compliance. Additionally, RPA can help to free up employees’ time so that they can focus on more strategic tasks, and it can provide valuable insights through data analytics.
</p>              <p className="nerds_text pt-2">
Why is RPA transformative? 
</p>              <p className="nerds_text pt-2">
There are many reasons why RPA is transformative. One reason is that RPA can automate tasks that are traditionally done by humans. This can free up employees to do other tasks that are more value-added. Additionally, RPA can help organizations to improve accuracy and efficiency while reducing costs.

              </p>
            </div>
          </div>
        </div>
      </section>

    
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default RoboticProcessAutomation;
