function PortfolioItems({data}){
    const overlayin = (datas) => {
        console.log(datas);
        document.getElementById(datas).style.display = "block";
    }
    const overlayout = (datas) => {
        document.getElementById(datas).style.display = "none";
    }
    const redirect = (datas) => {
        window.location.href = datas;
    }
    return (
        <div className="row ">
        {
          data.map((value)=>{
           const {id, image, title, description,link} = value;
           return (
            <div className="col-sm-4 my-4" key={id}>
             <div className="card bg-light text-center card-style" onMouseEnter={e => overlayin(description)} onMouseLeave={e=>overlayout(description)} onClick={e=>redirect(link)}>
                 <img src={image} className="img-fluid slide-in-fwd-center portfolio-image" style={{height:"416px"}}/>
                 <div className="portfolio-inner-text text-start overlay-css" id={description}>
                    <h3 style={{"color":"white"}}>{title}</h3>
                    <p style={{"color":"white"}}>{description}</p>
                 </div>
            </div>
         </div>
           )
          })
        }

</div>
    )
}

export default PortfolioItems;