import React, { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'
import PortfolioPageData from "../../Data/PortfolioPageData";
import PortfolioItems from "./PortfolioItems";
import PortfolioTabs from "./PortfolioTabs";
import './Portfolio.css';

const PortfolioPage = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  const [data, setData] = useState(PortfolioPageData);
  const categoryData = PortfolioPageData.map((value)=>{
        return value.category
   });
  const tabsData= ["Show all", ...new Set(categoryData)];
  
  const filterCategory=(category) =>{
      if(category=="Show all"){
          setData(PortfolioPageData);
          return;
      }
     const filteredData =  PortfolioPageData.filter((value)=>{
         return value.category == category;
     })
     setData(filteredData);
  }  
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('Our works - In our FlutterFarm, there are no Herds! We have only Nerds!');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Portfolio</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center pt-2">
              <p className="saving-cost">Our works</p>
            </div>
          </div>
          <div className="row pt-2">
                <div className="col-xl-12">
                    <PortfolioTabs filterCategory={filterCategory} tabsData={tabsData}/>
                    <PortfolioItems data={data} />
                </div>
              </div>
          </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default PortfolioPage;
