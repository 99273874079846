import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

function Footer() {
  return (
    <React.Fragment>
      <div className="footer mt-5 pt-5">
      <hr className="mx-5" />
        <div className="container">
          <div className="row py-4 mb-2">
            <div className="col-12 text-center text-md-start">
              {/* <p className='footer_head'>Logo</p> */}
              <img
                src="/assets/img/logo/Logo6.svg"
                className="img-fluid"
                width={150}
                alt="logo"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">What we provide</p>
              <p>
                <Link to="/mobile-app-development" className="footer_links">
                  Mobile App development
                </Link>
              </p>
              <p>
                <Link to="/web-app-development" className="footer_links">
                  Web App development
                </Link>
              </p>
              <p>
                <Link to="/desktop-app-development" className="footer_links">
                  Desktop App development
                </Link>
              </p>
              <p>
                <Link to="/ui-ux-designing" className="footer_links">
                  UI/UX Design
                </Link>
              </p>
              <p>
                <Link to="/support" className="footer_links">
                  Support and Maintenance
                </Link>
              </p>
            </div>
            <div className="col-md-4 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">Other Links</p>
              {/* <p className='footer_links'>Success Stores</p> */}
              <p>
                <Link to="/aboutus" className="footer_links">
                  About Us
                </Link>
              </p>
              <p>
                <Link to="/contactus" className="footer_links">
                  Contact Us
                </Link>
              </p>
              <p>
                <Link to="/blogs" className="footer_links">
                  Blogs
                </Link>
              </p>              
              <p>
                <Link to="/portfolio" className="footer_links">
                  Portfolio
                </Link>
              </p>                            
              {/* <p className='footer_links'>Careers </p> */}
              {/* <p className='footer_links'>Terms and Conditions</p>
                        <p className='footer_links'>Support and Maintenance</p>
                        <p className='footer_links'>Privacy and cookie policy</p> */}
            </div>
            <div className="col-md-4 text-center text-md-start pt-4 pt-md-0">
              <p className="footer_head">Follow Us</p>
              <p>
                <a
                  href="https://www.facebook.com/FlutterfarmOfficial"
                  className="footer_links-social-media"
                >
                  <i className="fa-brands fa-facebook">
                    <span className="footer_links ps-2">Facebook</span>
                  </i>
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/flutterfarm_dev/"
                  className="footer_links-social-media"
                >
                  <i className="fa-brands fa-instagram">
                    <span className="footer_links ps-2">Instagram</span>
                  </i>
                </a>
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/company/flutterfarm/"
                  className="footer_links-social-media"
                >
                  <i className="fa-brands fa-linkedin-in">
                    <span className="footer_links ps-2">Linkedin</span>
                  </i>
                </a>
              </p>
              <p>
                <a
                  href="https://twitter.com/flutterfarm_dev"
                  className="footer_links-social-media"
                >
                  <i className="fa-brands fa-twitter">
                    <span className="footer_links ps-2">Twitter</span>
                  </i>
                </a>
              </p>

              {/* <p><i className="fa-brands fa-youtube"><span className='footer_links ps-2'>Youtube</span></i></p> */}
            </div>
          </div>
          <hr />
          <p className="footer_links text-center">
            @ Copyright 2022 by FlutterFarm. All rights reserved.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
