import React, { useEffect,useState } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import db from "../../components/Firebase";
import useDocumentTitle from '../../useDocumentTitle';
import { useParams } from "react-router-dom";

const BlogDetails = () => {  
  let docid = useParams();
  let [empty, setEmpty] = useState();
  let [title, setTitle] = useState();
  let [date, setDate] = useState();
  let [image, setImage] = useState();
  const [loading, setLoading] = useState("true");    
  useDocumentTitle('Flutterfarm - ' + docid.title);  
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const fetchBlogDetails = async () => {
      let title = docid.title
      let replace1 = title.split('-').join(' ');       
        await db.collection("flutterfarm_blogs").where("b_title","==",replace1).get().then((querySnapshot) => {      
            if(querySnapshot.empty){
                setEmpty(true);
            }   
            else{
                querySnapshot.forEach(element => {
                    setEmpty(false);                    
                    var data = element.data();
                    setTitle(data['b_title']);
                    let timestamp_int_convert = parseFloat(data['b_timestamp']);
                    let date = new Date(timestamp_int_convert*1000);
                    var date_creation = date.toLocaleDateString("en-US");                                            
                    setDate(date_creation);
                    setImage(data['b_image']);
                    setLoading("");        
                    document.getElementById("desc").innerHTML = data['b_detailed_desc'];
                });      
            }    
        });
    }
    useEffect(() => {
        onTop();
        fetchBlogDetails();
    }, []);
  return (
    <React.Fragment>
      <Navbar></Navbar>
      <section className="blog">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="blog_head">{title}</p>
            </div>
          </div>
        </div>
      </section>      
      <section>
      {loading === "true" ? 
        (
          <div className="row">
            <div className="col-12 text-center pt-5">
              <div
                className="spinner-grow text-center mx-auto"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        ) : (        
          <div className="container py-4">
            <div className="row hide_nerd_head">
              <div className="col-12 text-center">
                <p className="nerds_head">
                {title}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 my-auto">
                <p className="nerds_text pt-2" id="desc"></p>
              </div>
            </div>
          </div>
        )
      }
      </section>      
      <Footer></Footer>
    </React.Fragment>
  );
};

export default BlogDetails;
