/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import validator from "validator";

import "./EstimatePage.css";
import useDocumentTitle from '../../useDocumentTitle'
import country from "../Countries";

import { PopupButton, CalendlyEventListener } from "react-calendly";

const EstimatePage = () => {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");  
  const [phoneError, setPhoneError] = useState("");    
  const [calendlyEvent, setCalendlyEvent] = useState(false);
  let navigate = useNavigate();
  const initialvalue = {
    Desktop: "",
    Mobile: "",
    Web: "",
    UIUX: "",
    Social: "",
  };
  useEffect(() => {
      getGeoInfo();         
  }, []);      
  useDocumentTitle('Flutterfarm – Estimation for your dream project!');

  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");  
  const [emailId, setEmailId] = useState("");
  const [projecttype, setProjectType] = useState("");
  const [projectplatform, setProjectPlatform] = useState("");  
  const [projectDes, setProjectDes] = useState("");
  const [hear, setHear] = useState("");
  const [budget, setBudget] = useState("");
  const [refEmail, setRefEmail] = useState("");
  const [deployApps, setDeployApps] = useState(initialvalue);
  const [calendlyurl, setcalendly] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [maxlength, setMaxLength] = useState("");
  // checkbox
  const [isCheckedDesktop, setIsCheckedDesktop] = useState(false);
  const [isCheckedMobile, setIsCheckedMobile] = useState(false);
  const [isCheckedWeb, setIsCheckedWeb] = useState(false);
  const [isCheckedUIUX, setIsCheckedUIUX] = useState(false);
  const [isCheckedSocial, setIsCheckedSocial] = useState(false);

  const [screen, setScreen] = useState("");
  const [triggeredEvents, setTriggeredEvents] = useState("");
  const [db, setDb] = useState("");
  const [uiux, setUiux] = useState("");

  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState("");

  function getCountryByCode(code) {
    return country.filter(
      function(country) {
        return country.code == code
      }
    );
  }
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_calling_code);  
        var found = getCountryByCode(data.country_code);     
        setMaxLength(found[0]['max_length']);   
      })
      .catch((error) => {
      });
  };      
    
  const getSteps = () => {
    return [
      "namaste",
      "email",
      "project",
      "deploy",
      "knownus",
      "budget",
      "high budget",
      "small budget",
      "screens",
      "clickables",
      "database",
      "UIUX",
      "Cost Estimation",
    ];
  };
  const steps = getSteps();
  

  //Navigates to the next page
  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((nextStep) => nextStep + 1);
    setDisable(true);
  };

  //Navigates to the Previous page
  // const handleBack = () => {

  //   if (activeStep === 7) {
  //     setActiveStep(6)
  //   }
  //   setActiveStep((previousStep) => previousStep - 1)
  //   setDisable(false)

  // }

  const hadleSubmit = async (e) => {

    let postData = {
      email: emailId,
      p_title: projectDes,
      p_type: deployApps,
      about_us: hear,
      person_email: refEmail,
      p_budget: budget,
      appointment_date: "10/12/2022",
      appointment_time: "10.00 AM",
      p_screens: screen,
      p_trigger_event: triggeredEvents,
      p_database: db,
      aleray_designed: uiux,
    };

    setLoading("true");

    try {
      const data = await axios
        .post(
          `https://us-central1-unobiweb-v1.cloudfunctions.net/submitEstimate`,
          postData
        )
        .then((res) => {
          // console.log(res)
          alert("Successful submitted");
          // console.log(res)

          setLoading("true");
          // console.log(emailId)
          setActiveStep("");
          setEmailId("");
          setProjectDes("");
          setHear("");
          setBudget("");
          setRefEmail("");
          setDeployApps("");
          // console.log(emailId)

          navigate(`/home`);
        });
    } catch (error) {
    }
  };

  // Handling Checkbox
  const handlecheck = (e, name, checking) => {
    if (checking === "isCheckedDesktop" && isCheckedDesktop === true) {
      setDeployApps({
        ...deployApps,
        Desktop: "",
      });
      setIsCheckedDesktop(!isCheckedDesktop);
    } else {
      setIsCheckedDesktop(!isCheckedDesktop);
      setDeployApps({
        ...deployApps,
        [name]: e,
      });
    }
    // console.log(deployApps)
    checkEnable();
  };
  // console.log(deployApps)

  const checkEnable = () => {
    if (deployApps !== null) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    // console.log(deployApps)
  };

  const handlecheckmobile = (e, name, checking) => {
    if (checking === "isCheckedMobile" && isCheckedMobile === true) {
      setDeployApps({
        ...deployApps,
        Mobile: "",
      });
      setIsCheckedMobile(!isCheckedMobile);
    } else {
      setIsCheckedMobile(!isCheckedMobile);
      setDeployApps({
        ...deployApps,
        [name]: e,
      });
    }
  };

  const handlecheckweb = (e, name, checking) => {
    if (checking === "isCheckedWeb" && isCheckedWeb === true) {
      setDeployApps({
        ...deployApps,
        Web: "",
      });
      setIsCheckedWeb(!isCheckedWeb);
    } else {
      setIsCheckedWeb(!isCheckedWeb);
      setDeployApps({
        ...deployApps,
        [name]: e,
      });
    }
  };

  const handlecheckuiux = (e, name, checking) => {
    if (checking === "isCheckedUIUX" && isCheckedUIUX === true) {
      setDeployApps({
        ...deployApps,
        UIUX: "",
      });
      setIsCheckedUIUX(!isCheckedUIUX);
    } else {
      setIsCheckedUIUX(!isCheckedUIUX);
      setDeployApps({
        ...deployApps,
        [name]: e,
      });
    }
  };

  const handlechecksocial = (e, name, checking) => {
    if (checking === "isCheckedSocial" && isCheckedSocial === true) {
      setDeployApps({
        ...deployApps,
        Social: "",
      });
      setIsCheckedSocial(!isCheckedSocial);
    } else {
      setIsCheckedSocial(!isCheckedSocial);
      setDeployApps({
        ...deployApps,
        [name]: e,
      });
    }
  };

  const hearUsOn = (e) => {
    if (hear !== "") {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  // Get Started Page
  const namaste = () => (
    <div className="text-center">
      <p className="hello_head">Hello.</p>
      <p className="hello_subtext">
        My name is John. I am going to support you to make on estimation.
      </p>
      <a className="btn btn-lg touch_btnmod" onClick={handleNext}>
        {" "}
        Lets Get Started
      </a>
    </div>
  );

  // Whats your Email
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("Valid Email");
    } else {
      setEmailError("Please provide valid Email.");
    }
  };
  // Whats your Name
  const validateName = (e) => {
    var name = e.target.value;

    if (name === "") {
      setNameError("Please provide valid name.");
    } else {
      setNameError("");
    }
  };  
  // Whats your Phone
  const validatePhone = (e) => {
    var phone = e.target.value;
    let lent = phone.length;
    if (phone === "") {
      setPhoneError("Please provide valid phone.");
    } else {
      if(lent === 10){
        setPhoneError("");
      }
      else{
        setPhoneError("Please provide valid phone.");
      }
    }
  };    
  const email = () => {
    const handleNextEmail = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackEmail = () => {
      setActiveStep((previousStep) => previousStep - 1);

      setDisable(false);
    };

    return (
      <div className="text-start">
        <p className="namasate_head text-center text-md-start">
          Fill your details
        </p>
        <div className="mb-5 pt-3">
          <input
            className="form-control input_mod"
            placeholder="John"
            type="text"
            required
            name="name"
            id="name"
            value={name}
            // onChange={(e) => setEmailId(e.target.value)}
            onChange={(e) => (setName(e.target.value), validateName(e))}
            autoFocus
          />
          <span
            style={
              nameError !== "Valid Email"
                ? {
                    fontFamily: "raleway",
                    fontWeight: "500",
                    color: "#EB3838",
                    fontSize: "14px",
                  }
                : {
                    fontFamily: "raleway",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    fontSize: "14px",
                  }
            }
          >
            {nameError}
          </span>
        </div>        
        <div className="mb-5 pt-3">
          <div className="input-group">
          <label style={{"paddingTop":"8px","paddingRight":"6px"}}>{countrycode}</label>
          <input
            className="form-control input_mod"
            placeholder="9854258952"
            type="text"
            required
            name="phone"
            id="phone"
            pattern="^[0-9]*$"
            maxLength={maxlength}            
            value={phone}
            // onChange={(e) => setEmailId(e.target.value)}
            onChange={(e) => (setPhone(e.target.value), validatePhone(e))}
          />
          </div>
          <span
            style={
              phoneError !== "Valid Email"
                ? {
                    fontFamily: "raleway",
                    fontWeight: "500",
                    color: "#EB3838",
                    fontSize: "14px",
                  }
                : {
                    fontFamily: "raleway",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    fontSize: "14px",
                  }
            }
          >
            {phoneError}
          </span>
        </div>        
        <div className="mb-5 pt-3">
          <input
            className="form-control input_mod"
            placeholder="name@example.com"
            type="text"
            required
            name="emailId"
            id="emailId"
            value={emailId}
            // onChange={(e) => setEmailId(e.target.value)}
            onChange={(e) => (setEmailId(e.target.value), validateEmail(e))}
          />
          <span
            style={
              emailError !== "Valid Email"
                ? {
                    fontFamily: "raleway",
                    fontWeight: "500",
                    color: "#EB3838",
                    fontSize: "14px",
                  }
                : {
                    fontFamily: "raleway",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    fontSize: "14px",
                  }
            }
          >
            {emailError}
          </span>
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackEmail}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={emailError !== "Valid Email" || nameError !== "" || phoneError !== "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextEmail}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };
  const SubmitData = async () => {
    let postData = {
      name : name,
      email: emailId,
      phone : countrycode + phone,
      projecttype : projecttype,
      projectplatform : projectplatform,
    };


    try {
      const data = await axios
        .post(
          `https://us-central1-unobiweb-v1.cloudfunctions.net/submitEstimate`,
          postData
        )
        .then((res) => {
        });
    } catch (error) {
    }
  }
  // Whats your in your mind
  const project = () => {
    const handleNextProject = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 2);
      if(projecttype === "App" || projecttype == "Website" || projecttype == "Poster"){
      }
      else{
        SubmitData();
      }
      setDisable(true);
    };
    const handleBackProject = () => {
      setActiveStep((previousStep) => previousStep - 1);

      setDisable(false);
    };

    return (
      <div>
        <p className="namasate_head text-center text-md-start">
          Which service are you interested in?
        </p>

        <div className="radio_mod mb-5">
          <label>
            <input
              type="radio"
              className="option-input radio"
              checked={projecttype === "App" ? true : false}
              value={"App"}
              name="hearus"
              onChange={(e) => {
                setProjectType(e.target.value);
                setDisable(false);
              }}
            />
            <span className="ps-2">App</span>
          </label>
          <br />
          <label>
            <input
              type="radio"
              className="option-input radio"
              value={"Website"}
              name="hearus"
              checked={projecttype === "Website" ? true : false}
              onChange={(e) => {
                setProjectType(e.target.value);
                hearUsOn();
              }}
            />
            <span className="ps-2">Website</span>
          </label>
          <br />
          <label>
            <input
              type="radio"
              className="option-input radio"
              value="App & Website"
              checked={projecttype === "App & Website" ? true : false}
              name="hearus"
              onChange={(e) => {
                setProjectType(e.target.value);
                setDisable(false);
              }}
            />
            <span className="ps-2 pt-5">App & Website</span>
          </label>
          <br />
          <label>
            <input
              type="radio"
              className="option-input radio"
              value={"UI/UX"}
              name="hearus"
              checked={projecttype === "UI/UX" ? true : false}
              onChange={(e) => {
                setProjectType(e.target.value);
                hearUsOn();
              }}
            />
            <span className="ps-2">UI/UX</span>
          </label>
          <br />
          <label>
            <input
              type="radio"
              className="option-input radio"
              value={"Poster"}
              name="hearus"
              checked={projecttype === "Poster" ? true : false}
              onChange={(e) => {
                setProjectType(e.target.value);
                hearUsOn();
              }}
            />

            <span className="ps-2">Poster Design</span>
          </label>
          <br />
          <label>
            <input
              type="radio"
              className="option-input radio"
              value={"Logo Design"}
              name="hearus"
              checked={projecttype === "Logo Design" ? true : false}
              onChange={(e) => {
                setProjectType(e.target.value);
                hearUsOn();
              }}
            />
            <span className="ps-2">Logo Design</span>
          </label>
          <br />
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackProject}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={projecttype === "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextProject}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Where do you want to deploy
{/*  const deploy = () => {
    const handleNextDeploy = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackDeploy = () => {
      setActiveStep((previousStep) => previousStep - 1);

      setDisable(false);
    };
    return (
      <div>
        <p className="namasate_head text-center text-md-start">
          Where/What do you want to deploy ?
        </p>
        <div className="d-flex justify-content-start">
          <label className="checkbox path mt-1">
            <input
              type="checkbox"
              name="Desktop"
              value={"Desktop"}
              checked={isCheckedDesktop}
              onChange={(e) =>
                handlecheck(e.target.value, "Desktop", "isCheckedDesktop")
              }
            />
            <svg viewBox="0 0 21 21">
              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
            </svg>
          </label>
          <p className="check_box_text ms-2">Desktop</p>
        </div>

        <div className="d-flex justify-content-start">
          <label className="checkbox path mt-1">
            <input
              type="checkbox"
              value="Mobile"
              name="Mobile"
              checked={isCheckedMobile}
              onChange={(e) =>
                handlecheckmobile(e.target.value, "Mobile", "isCheckedMobile")
              }
            />
            <svg viewBox="0 0 21 21">
              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
            </svg>
          </label>
          <p className="check_box_text ms-2">Mobile</p>
        </div>

        <div className="d-flex justify-content-start">
          <label className="checkbox path mt-1">
            <input
              type="checkbox"
              value="Web"
              name="Web"
              checked={isCheckedWeb}
              onChange={(e) =>
                handlecheckweb(e.target.value, "Web", "isCheckedWeb")
              }
            />
            <svg viewBox="0 0 21 21">
              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
            </svg>
          </label>
          <p className="check_box_text ms-2">Web</p>
        </div>

        <div className="d-flex justify-content-start">
          <label className="checkbox path mt-1">
            <input
              type="checkbox"
              value="UIUX"
              name="UIUX"
              checked={isCheckedUIUX}
              onChange={(e) =>
                handlecheckuiux(e.target.value, "UIUX", "isCheckedUIUX")
              }
            />
            <svg viewBox="0 0 21 21">
              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
            </svg>
          </label>
          <p className="check_box_text ms-2">UI/UX Designing </p>
        </div>

        <div className="d-flex justify-content-start">
          <label className="checkbox path mt-1">
            <input
              type="checkbox"
              value="Social"
              name="Social"
              checked={isCheckedSocial}
              onChange={(e) =>
                handlechecksocial(e.target.value, "Social", "isCheckedSocial")
              }
            />
            <svg viewBox="0 0 21 21">
              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
            </svg>
          </label>
          <p className="check_box_text ms-2">Social Media marketing </p>
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackDeploy}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={
                isCheckedDesktop === true ||
                isCheckedMobile === true ||
                isCheckedWeb === true ||
                isCheckedUIUX === true ||
                isCheckedSocial === true
                  ? false
                  : true
              }
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextDeploy}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };  */}

  const hearus = () => {
    const handleNextHearus = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);
      SubmitData();
      setDisable(true);
    };
    const handleBackHearus = () => {
      setActiveStep((previousStep) => previousStep - 2);
      setProjectPlatform("");
      setDisable(false);
    };

    return (
      <div>
          {
            projecttype === "App" || projecttype === "Website" ? (
              projecttype === "App" ? (
                <p className="namasate_head text-center text-md-start">
                  Which platform are you interested in?
                </p>      
              ) : (
                <p className="namasate_head text-center text-md-start">
                  Which kind of website are you interested in?
                </p>      
              )
            ) : (
              <p className="namasate_head text-center text-md-start">
                {projecttype}
              </p>                    
            )
          }

        <div className="radio_mod mb-5">
          {
            projecttype === "App" ? (
              <div>
                <label>
                <input
                  type="radio"
                  className="option-input radio"
                  value="Android"
                  checked={projectplatform === "Android" ? true : false}
                  name="hearus"
                  onChange={(e) => {
                    setProjectPlatform(e.target.value);
                    setDisable(false);
                  }}
                />
                <span className="ps-2 pt-5">Android</span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    className="option-input radio"
                    checked={projectplatform === "Ios" ? true : false}
                    value={"Ios"}
                    name="hearus"
                    onChange={(e) => {
                      setProjectPlatform(e.target.value);
                      setDisable(false);
                    }}
                  />
                  <span className="ps-2">iOS</span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    className="option-input radio"
                    value={"Android & Ios"}
                    name="hearus"
                    checked={projectplatform === "Android & Ios" ? true : false}
                    onChange={(e) => {
                      setProjectPlatform(e.target.value);
                      hearUsOn();
                    }}
                  />
                  <span className="ps-2">Android & iOS</span>
                </label>
                <br />
              </div>  
            ) : (
              <span></span>
            )
          }
          {
            projecttype === "Website" ? (
              <div>
                <label>
                <input
                  type="radio"
                  className="option-input radio"
                  value="Ecommerce"
                  checked={projectplatform === "Ecommerce" ? true : false}
                  name="hearus"
                  onChange={(e) => {
                    setProjectPlatform(e.target.value);
                    setDisable(false);
                  }}
                />
                <span className="ps-2 pt-5">Ecommerce</span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    className="option-input radio"
                    checked={projectplatform === "Blog/Portfolio" ? true : false}
                    value={"Blog/Portfolio"}
                    name="hearus"
                    onChange={(e) => {
                      setProjectPlatform(e.target.value);
                      setDisable(false);
                    }}
                  />
                  <span className="ps-2">Blog/Portfolio</span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    className="option-input radio"
                    value={"Commercial"}
                    name="hearus"
                    checked={projectplatform === "Commercial" ? true : false}
                    onChange={(e) => {
                      setProjectPlatform(e.target.value);
                      hearUsOn();
                    }}
                  />
                  <span className="ps-2">Commercial</span>
                </label>
                <br />
              </div>  
            ) : (
              <span></span>
            )
          }          
          {
            projecttype === "App & Website" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 90,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Android app</li>
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>iOS app</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Website</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin app</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Payment Gateway : Razorpay, Stripe, Paypal</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Database : Firebase,MySQL</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 4 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                    
          {
            projecttype === "UI/UX" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 2000<span className="period">/per page</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>App UI/UX</li>
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Website UI/UX</li>
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }    
          {
            projecttype === "Poster" ? (
              <div>
                <label>
                <input
                  type="radio"
                  className="option-input radio"
                  value="Single"
                  checked={projectplatform === "Single" ? true : false}
                  name="hearus"
                  onChange={(e) => {
                    setProjectPlatform(e.target.value);
                    setDisable(false);
                  }}
                />
                <span className="ps-2 pt-5">Single poster design</span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    className="option-input radio"
                    checked={projectplatform === "Multiple" ? true : false}
                    value={"Multiple"}
                    name="hearus"
                    onChange={(e) => {
                      setProjectPlatform(e.target.value);
                      setDisable(false);
                    }}
                  />
                  <span className="ps-2">Above 20 poster design</span>
                </label>
                <br />
              </div>  
            ) : (
              <span></span>
            )
          }   
          {
            projecttype === "Logo Design" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 2000<span className="period">/per logo</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Brand logo</li>
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                         
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackHearus}
            >
              {" "}
              Back
            </button>
            {
              projecttype === "App" || projecttype == "Website" || projecttype == "Poster" ? (
                <button
                type="button"
                disabled={projectplatform === "" ? true : false}
                className="btn btn-lg touch_btnmod ms-3"
                onClick={handleNextHearus}
              >
                Next
              </button>  
              ) : (
                <span></span>                
              )
            }
          </div>
        </div>
      </div>
    );
  };

  const projectbudget = () => {
    const handleNextProjectBudget = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);
      setcalendly("https://calendly.com/ganeshkumarcgk/project-discussion?embed_domain=tcampb.github.io&embed_type=pop&email=" + emailId + '&a1=' + projectDes)
      setDisable(true);
    };
    const handleBackProjectBudget = () => {
      setActiveStep((previousStep) => previousStep - 1);

      setDisable(false);
    };

    return (
      <div>
        <p className="namasate_head text-center text-md-start">
        {projectplatform} {projecttype}
        </p>
        
        <div className="radio_mod mb-5">
        {
            projecttype === "App" && projectplatform === "Android" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 60,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>eCommerce user app</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin app</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Payment Gateway : Razorpay, Stripe, Paypal</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Database : Firebase,MySQL</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 4 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                         
        {
            projecttype === "App" && projectplatform === "Ios" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 70,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>eCommerce user app</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin app</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Payment Gateway : Razorpay, Stripe, Paypal</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Database : Firebase,MySQL</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 4 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                   
        {
            projecttype === "App" && projectplatform === "Android & Ios" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 80,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>eCommerce user app</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin app</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Payment Gateway : Razorpay, Stripe, Paypal</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Database : Firebase,MySQL</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 4 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                             
        {
            projecttype === "Website" && projectplatform === "Ecommerce" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 40,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>eCommerce website</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin app</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Hosting : Firebase</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Payment Gateway : Razorpay, Stripe, Paypal</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Database : Firebase,MySQL</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 4 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                                       
        {
            projecttype === "Website" && projectplatform === "Blog/Portfolio" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 25,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Blog/Portfolio website</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin panel</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Hosting : Firebase/Hostinger</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 3 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                                                 
        {
            projecttype === "Website" && projectplatform === "Commercial" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 28,000<span className="period"></span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Commercial website</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Admin panel</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Hosting : Firebase/Hostinger</li>                      
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>Duration : 2 weeks</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                                                           
        {
            projecttype === "Poster" && projectplatform === "Single" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center">&#x20b9; 600<span className="period">(per design)</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>1 Social media post</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>2 different sizes (Facebook and Twitter)</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                                                                     
        {
            projecttype === "Poster" && projectplatform === "Multiple" ? (
              <div>
              <div className="pricing">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h6 className="card-price text-center"><del style={{"fontSize":"25px"}}>&#x20b9; 600</del> &#x20b9; 550<span className="period">(per design)</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Social media post</li>                                            
                      <li><span className="fa-li"><i className="fas fa-check"></i></span>2 different sizes(facebook and twitter)</li>                      
                    </ul>
                  </div>
                </div>
              </div>
              <br></br>
              <p>Thank you for choosing <b>FlutterFarm</b>. Our support team will contact you.</p>
              </div>
            ) : (
              <span></span>
            )
          }                                                                                                                               
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackProjectBudget}
            >
              {" "}
              Back
            </button>
{/*            <button
              type="button"
              disabled={budget === "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextProjectBudget}
            >
              {" "}
              Next
        </button>   */}
          </div>
        </div>
      </div>
    );
  };

  const bigProject = () => {
    const handleBackHearus = () => {
      setActiveStep((previousStep) => previousStep - 1);

      setDisable(false);
    };

    return (
      <div className="text-center pb-3">
        <p className="appoinment_head pt-4">
          Our Project manager will call you to discuss more details. Please an
          appointment time
        </p>
        {loading === "true" ? (
          <div
            className="spinner-grow"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <CalendlyEventListener
            onDateAndTimeSelected={function noRefCheck() {}}
            onEventScheduled={(e) => {
              e.isTrusted ? setCalendlyEvent(true) : setCalendlyEvent(false);
              e.isTrusted ? hadleSubmit() : setCalendlyEvent(false);              
            }}
            onEventTypeViewed={function noRefCheck() {}}
            onProfilePageViewed={function noRefCheck() {}}
          >
            <PopupButton
              url = {calendlyurl}
              rootElement={document.getElementById("root")}
              text="Click here to schedule!"
              className="calendly_btn_mod px-3 my-4"
            />
            <div

            // style={{
            //   minWidth:"320px",
            //   height:"450px"
            // }}
            >
              {/* <iframe
        className="scroll"
        frameBorder="0"
        height="100%"
        src="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=popup"
            width="100%"
            title="calendly-meeting"
          /> */}
            </div>
          </CalendlyEventListener>
        )}

        {/* <CalendlyEventListener        
        onDateAndTimeSelected={function noRefCheck() {}}
        onEventScheduled={(e) => {(e.isTrusted ? setCalendlyEvent(true):setCalendlyEvent(false)) }}
        onEventTypeViewed={function noRefCheck() {}}
        onProfilePageViewed={function noRefCheck() {}}
      >
        <PopupButton
        url="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=pop"
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        className='calendly_btn_mod px-3 my-4'
        />         
        <div 
        
          style={{
            minWidth:"320px",
            height:"450px"
          }}
        >
        <iframe
        className="scroll"
        frameBorder="0"
        height="100%"
        src="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=popup"
            width="100%"
            title="calendly-meeting"
          />
        </div>
      </CalendlyEventListener> */}

        <br />
        <button
          type="button"
          className="btn btn-lg touch_btnmod"
          onClick={handleBackHearus}
        >
          {" "}
          Back
        </button>

{/*        <button
          type="button"
          className="btn btn-lg ms-4 touch_btnmod"
          disabled={calendlyEvent ? false : true}
          onClick={hadleSubmit}
        >
          {" "}
          Submit
    </button> */}
      </div>
    );
  };

  const screens = () => {
    const handleNextScreens = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackScreens = () => {
      setActiveStep(5);
      setDisable(false);
    };

    return (
      <div>
        <p className="namasate_head text-center text-md-start">
          How many screens/views that you are looking for ?
        </p>
        <div className="radio_mod mb-5">
          <label>
            <div className="row">
              <div className="col-2">
                <input
                  type="radio"
                  className="option-input radio"
                  name="screens"
                  value="Less than or equal to 5"
                  checked={screen === "Less than or equal to 5" ? true : false}
                  onChange={(e) => setScreen(e.target.value)}
                />
              </div>
              <div className="col-10 my-auto">
                <p className="ps-2 pt-3">Less than or equal to 5</p>
              </div>
            </div>
          </label>{" "}
          <br />
          <label className="mt-4">
            <div className="row">
              <div className="col-2">
                <input
                  type="radio"
                  className="option-input radio"
                  name="screens"
                  value="Less than or equal to 15"
                  checked={screen === "Less than or equal to 15" ? true : false}
                  onChange={(e) => setScreen(e.target.value)}
                />
              </div>
              <div className="col-10 my-auto">
                <p className="ps-2 pt-3">Less than or equal to 15</p>
              </div>
            </div>
          </label>{" "}
          <br />
          <label className="mt-4">
            <div className="row">
              <div className="col-2">
                <input
                  type="radio"
                  className="option-input radio"
                  name="screens"
                  value="more than 15"
                  checked={screen === "more than 15" ? true : false}
                  onChange={(e) => setScreen(e.target.value)}
                />
              </div>
              <div className="col-10 my-auto">
                <p className="ps-3 ps-md-4 pt-3">More than 15</p>
              </div>
            </div>
          </label>{" "}
          <br />
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackScreens}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={screen === "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextScreens}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  const clickables = () => {
    const handleNextClickables = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackClickables = () => {
      setActiveStep((previousStep) => previousStep - 1);
      setDisable(false);
    };

    return (
      <div>
        <p className="namasate_head text-center text-md-start">
          In each Screen/view, how many clickable/triggered events that you are
          looking for ?
        </p>
        <div className="radio_mod mb-5">
          <label>
            <div className="row">
              <div className="col-2">
                <input
                  type="radio"
                  className="option-input radio"
                  name="clickables"
                  value="Less than or equal to 5"
                  checked={
                    triggeredEvents === "Less than or equal to 5" ? true : false
                  }
                  onChange={(e) => setTriggeredEvents(e.target.value)}
                />
              </div>
              <div className="col-10 my-auto">
                <p className="ps-2 pt-3">Less than or equal to 5</p>
              </div>
            </div>
          </label>{" "}
          <br />
          <label className="mt-4">
            <div className="row">
              <div className="col-2">
                <input
                  type="radio"
                  className="option-input radio"
                  name="clickables"
                  value="More than 5"
                  checked={triggeredEvents === "More than 5" ? true : false}
                  onChange={(e) => setTriggeredEvents(e.target.value)}
                />
              </div>
              <div className="col-10 my-auto">
                <p className="ps-4 pt-3">More than 5</p>
              </div>
            </div>
          </label>{" "}
          <br />
        </div>
        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackClickables}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={triggeredEvents === "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextClickables}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  const database = () => {
    const handleNextDatabases = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackDatabases = () => {
      setActiveStep((previousStep) => previousStep - 1);
      setDisable(false);
    };

    return (
      <div className="py-4">
        <p className="namasate_head text-center text-md-start">
          What kind of database are you using?
        </p>
        <div className="radio_mod mb-5">
          <label>
            <input
              type="radio"
              className="option-input radio"
              name="db"
              value="Firebase"
              checked={db === "Firebase" ? true : false}
              onChange={(e) => setDb(e.target.value)}
            />
            <span className="ps-2">Firebase</span>
          </label>{" "}
          <br />
          <label className="mt-4">
            <input
              type="radio"
              className="option-input radio"
              name="db"
              value="Mysql"
              checked={db === "Mysql" ? true : false}
              onChange={(e) => setDb(e.target.value)}
            />
            <span className="ps-2">Mysql</span>
          </label>{" "}
          <br />
          <label className="mt-4">
            <input
              type="radio"
              className="option-input radio"
              name="db"
              value="MongoDB"
              checked={db === "MongoDB" ? true : false}
              onChange={(e) => setDb(e.target.value)}
            />
            <span className="ps-2">MongoDB</span>
          </label>{" "}
          <br />
          <label className="mt-4">
            <input
              type="radio"
              className="option-input radio"
              name="db"
              value="other"
              checked={db === "other" ? true : false}
              onChange={(e) => setDb(e.target.value)}
            />
            <span className="ps-2">other</span>
          </label>{" "}
          <br />
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackDatabases}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={db === "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextDatabases}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  const UIUX = () => {
    const handleNextUIUX = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackUIUX = () => {
      setActiveStep((previousStep) => previousStep - 1);
      setDisable(false);
    };

    return (
      <div>
        <p className="namasate_head text-center text-md-start">
          Do you already have UI/UX?
        </p>
        <div className="radio_mod mb-5">
          <label>
            <input
              type="radio"
              className="option-input radio"
              name="uiux"
              value="Yes"
              checked={uiux === "Yes" ? true : false}
              onChange={(e) => setUiux(e.target.value)}
            />
            <span className="ps-2">Yes</span>
          </label>{" "}
          <br />
          <label className="mt-4">
            <input
              type="radio"
              className="option-input radio"
              name="uiux"
              value="No"
              checked={uiux === "No" ? true : false}
              onChange={(e) => setUiux(e.target.value)}
            />
            <span className="ps-2">No</span>
          </label>{" "}
          <br />
        </div>

        <div className="row">
          <div className="col-12 text-center text-md-start">
            <button
              type="button"
              className="btn btn-lg touch_btnmod"
              onClick={handleBackUIUX}
            >
              {" "}
              Back
            </button>
            <button
              type="button"
              disabled={uiux === "" ? true : false}
              className="btn btn-lg touch_btnmod ms-3"
              onClick={handleNextUIUX}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  const costEstimation = () => {
    const handleNextCostEstimation = (e) => {
      e.preventDefault();
      setActiveStep((nextStep) => nextStep + 1);

      setDisable(true);
    };
    const handleBackCostEstimation = () => {
      setActiveStep((previousStep) => previousStep - 1);
      setDisable(false);
    };

    return (
      <div className="container text-center py-4">
        <p className="quote_head">
          Based on the inputs, following are our approximate estimates. <br />
          This quote is not final. Our project manager will provide you a final
          quote.
        </p>
        <hr className="hr" />
        <p className="quote_subhead">Price For one Screen</p>
        <hr className="hr" />
        <table className="table table-responsive table-borderless mx-auto text-start quote_table">
          <tbody>
            <tr>
              <td>UX Design:</td>
              <td>&#x20b9; 25</td>
            </tr>
            <tr>
              <td>UI Design:</td>
              <td>&#x20b9; 50</td>
            </tr>
            <tr>
              <td>UI Programing:</td>
              <td>&#x20b9; 100</td>
            </tr>
            <tr>
              <td>5 Events:</td>
              <td>&#x20b9; 125</td>
            </tr>
            {/* <tr>
            <td >Total:</td>
            <td>&#x20b9; 300</td>
          </tr> */}
          </tbody>
        </table>

        <hr className="hr" />

        <table className="table table-responsive table-borderless mx-auto quote_table">
          <tbody>
            <tr>
              <td className="text-start">Total:</td>
              <td className="text-center">&#x20b9; 300</td>
            </tr>
          </tbody>
        </table>
        <p className="quote_discount pt-3">
          For more than 10 Screens, Get 10% Discount
        </p>

        <button
          type="button"
          className="btn btn-lg touch_btnmod"
          onClick={handleBackCostEstimation}
        >
          {" "}
          Back
        </button>
        <button
          type="button"
          disabled={db === "" ? true : false}
          className="btn btn-lg touch_btnmod ms-3"
          onClick={handleNextCostEstimation}
        >
          {" "}
          Next
        </button>
      </div>
    );
  };

  const smallAppointment = () => {
    //   const handleBackHearus = () => {
    //     setActiveStep((previousStep) => previousStep - 1)

    //     setDisable(false)
    //   }

    //   return(
    //   <div className='text-center'>
    //     <p className='appoinment_head'>Our Project manager will call you to discuss more details. Please an appointment time</p>
    //     {/* <InlineWidget url="https://calendly.com/dsurya/30mins" />       */}
    //     <PopupButton
    //       url="https://calendly.com/dsurya/30mins" className='appt_btn_mod mt-4'

    //       rootElement={document.getElementById("root")}
    //       text="Click here to schedule!"
    //     />
    //     <br />

    //     <button type='button' className="btn btn-lg touch_btnmod"  onClick={handleBackHearus}> Back</button>

    //     <button type='button' className="btn btn-lg touch_btnmod" onClick={hadleSubmit}> Submit</button>

    //   </div>
    // )

    const handleBackHearus = () => {
      setActiveStep((previousStep) => previousStep - 1);

      setDisable(false);
    };

    return (
      <div className="text-center pb-3">
        <p className="appoinment_head pt-4">
          Our Project manager will call you to discuss more details. Please an
          appointment time
        </p>
        {loading === "true" ? (
          <div
            className="spinner-grow"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <CalendlyEventListener
            onDateAndTimeSelected={function noRefCheck() {}}
            onEventScheduled={(e) => {
              e.isTrusted ? setCalendlyEvent(true) : setCalendlyEvent(false);
            }}
            onEventTypeViewed={function noRefCheck() {}}
            onProfilePageViewed={function noRefCheck() {}}
          >
            <PopupButton
              url="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=pop"
              rootElement={document.getElementById("root")}
              text="Click here to schedule!"
              className="calendly_btn_mod px-3 my-4"
            />
            <div

            // style={{
            //   minWidth:"320px",
            //   height:"450px"
            // }}
            >
              {/* <iframe
        className="scroll"
        frameBorder="0"
        height="100%"
        src="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=popup"
            width="100%"
            title="calendly-meeting"
          /> */}
            </div>
          </CalendlyEventListener>
        )}

        {/* <CalendlyEventListener        
        onDateAndTimeSelected={function noRefCheck() {}}
        onEventScheduled={(e) => {(e.isTrusted ? setCalendlyEvent(true):setCalendlyEvent(false)) }}
        onEventTypeViewed={function noRefCheck() {}}
        onProfilePageViewed={function noRefCheck() {}}
      >
        <PopupButton
        url="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=pop"
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        className='calendly_btn_mod px-3 my-4'
        />         
        <div 
        
          style={{
            minWidth:"320px",
            height:"450px"
          }}
        >
        <iframe
        className="scroll"
        frameBorder="0"
        height="100%"
        src="https://calendly.com/dsurya/30mins?embed_domain=tcampb.github.io&embed_type=popup"
            width="100%"
            title="calendly-meeting"
          />
        </div>
      </CalendlyEventListener> */}

        <br />
        <button
          type="button"
          className="btn btn-lg touch_btnmod"
          onClick={handleBackHearus}
        >
          {" "}
          Back
        </button>

        <button
          type="button"
          className="btn btn-lg ms-4 touch_btnmod"
          disabled={calendlyEvent ? false : true}
          onClick={hadleSubmit}
        >
          {" "}
          Submit
        </button>
      </div>
    );
  };

  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <section className="estimate_page vertical-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar fixed-top">
                <div className="container-fluid ">
                  <Link to={"/home"} className="navbar-brand">
                    <img
                      src="./assets/img/Logo.svg"
                      className="img-fluid logo"
                      alt="flutterfarm" loading="crazy"
                    />
                  </Link>
                  <form className="d-flex">
                    {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" /> */}
                    <Link to={"/home"} className="btn text-dark">
                      <i className="fa-solid fa-house-chimney"></i>
                      <span className="navbar_text ps-2">Home</span>
                    </Link>
                  </form>
                </div>
              </nav>
            </div>
          </div>
          <div className="row mt-5 mt-md-0">
            <div className="col-12">
              <div className="card card_mod_estimate cb1">
                <div className="card-body px-md-5 mx-3 mx-md-5 d-flex align-items-center justify-content-center">
                  {activeStep === 0 && namaste()}

                  {activeStep === 1 && email()}

                  {activeStep === 2 && project()}

{/*                  {activeStep === 3 && deploy()}  */}

                  {activeStep === 4 && hearus()}

                  {activeStep === 5 && projectbudget()}

                  {activeStep === 6 &&
                    (budget === "Above &#x20b9; 10000" || budget === "Below &#x20b9; 10000"
                      ? bigProject()
                      : setActiveStep(7))}

                  {activeStep === 7 &&
                    (budget === "Below &#x20b9; 10000" ? screens() : setActiveStep(5))}

                  {activeStep === 8 && clickables()}

                  {activeStep === 9 && database()}

                  {activeStep === 10 && UIUX()}

                  {activeStep === 11 && costEstimation()}

                  {activeStep === 12 && smallAppointment()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EstimatePage;
