import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./AboutUs.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const AboutUs = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('About - In our FlutterFarm, there are no Herds! We have only Nerds!');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">About Us</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 my-auto">
              <img
                src="./assets/img/about_us/nerds.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
                When we built our team, we focused on two things – <span className="highlight">our customers
                and our employees.</span> Any customer is served well only by the
                employees of a company. We thought foundation of a strong
                employee engagement comes from taking care of our employees from
                day one. So, all our employees are trained in our training
                programs during their final years in the college. Once they
                graduate, they not only graduate with their degrees/diplomas,
                but they are also ready to support our customers with high
                productivity and engagement from day one. Just try us out with
                simple one screen application for free and enjoy the journey with us.
              </p>
            </div>
            <div className="col-md-5 my-auto text-center">
              <img
                src="./assets/img/about_us/nerds.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg_explore">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4  text-center">
              <p className="num_ts">4+</p>
              <p className="sub_ts">Years of experience</p>
            </div>
            <div className="col-md-4 pt-5 pt-md-0 text-center">
              <p className="num_ts">10+</p>
              <p className="sub_ts">Experience of developers</p>
            </div>
            <div className="col-md-4 pt-5 pt-md-0 text-center">
              <p className="num_ts">40+</p>
              <p className="sub_ts">Happy clients</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="values_ts">OUR VALUES</p>
              <p className="values_head">
                Trust, Ownership and Growth – Our fuel for your business!
              </p>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-md-6 col-lg-4">
              <div className="card mx-auto card_values_mod mt-5 mt-md-0">
                <div className="card-body px-5">
                  <div className="row py-3">
                    <div className="col-12">
                      <img
                        src="./assets/img/about_us/trust.svg"
                        className="img-fluid"
                        width={55}
                        alt="trust" loading="crazy"
                      />
                      <p className="adabtability_ts pt-3">Trust</p>
                      <p className="adabtability_text">
                        The basis of building a company comes from trusting each
                        other. Whether it is the CEO of the company or an
                        intern, we run all our decisions based on mutual trust.
                        We extend this with our clients as well. So, we don’t
                        give false promises to our customers. We provide what
                        exactly needs to finish their projects – whether it is
                        cost, time or associated quality. We are highly focused
                        on long term partnerships, and we strongly believe, the
                        only way to maintain this is to gain trust with our
                        clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card mx-auto card_values_mod mt-5 mt-md-0">
                <div className="card-body px-5">
                  <div className="row py-3">
                    <div className="col-12">
                      <img
                        src="./assets/img/about_us/Ownership.svg"
                        className="img-fluid"
                        width={50}
                        alt="ownership" loading="crazy"
                      />
                      <p className="adabtability_ts pt-3">Ownership</p>
                      <p className="adabtability_text">
                        Trust transforms to Ownership. Our trust on our
                        employees drives them to take ownership of their work
                        and actions. This provides multi-fold advantages for our
                        clients. Our team will make sure there is a single point
                        ownership for each client to make sure our clients get
                        the right information and deliveries on time as agreed.
                        To improve and maintain ownership, we built agile teams
                        to execute projects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card mx-auto card_values_mod mt-5 mt-lg-0">
                <div className="card-body px-5">
                  <div className="row py-3">
                    <div className="col-12">
                      <img
                        src="./assets/img/about_us/growth.svg"
                        className="img-fluid"
                        width={50}
                        alt="growth" loading="crazy"
                      />
                      <p className="adabtability_ts pt-3">Growth</p>
                      <p className="adabtability_text">
                        Trust and ownership are the driving forces for our
                        growth. Our sales growth is just a part of our overall
                        growth – we continue our focus on growth of training,
                        development, well-being and compensation of our
                        employees and continue to make sure our clients also
                        grow their sales. Our clients’ satisfaction and growth
                        in sales are our measuring indicators for our overall
                        growth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="saving-cost">Saving cost for you ?</p>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-5 my-auto">
              <img
                src="./assets/img/about_us/remote-working.webp"
                className="img-fluid hide_md_neards"
                alt="saving cost" loading="crazy"
              />
            </div>
            <div className="col-md-7 my-auto text-center text-md-start">
              <p className="subhead-saving-cost">We are 100% remote.</p>
              <img
                src="./assets/img/about_us/remote-working.webp"
                className="img-fluid show_md_neards"
                width={400}
                alt="saving cost" loading="crazy"
              />
              <p className="text-saving-cost">
                Our company is built on the new framework of 100% remote. With
                100% remote, we do not have expensive office spaces,
                infrastructure, or high fixed costs. This saves significant
                amount of fixed costs, and we pass those savings to our
                customers. Even though we are 100% remote, we have a streamlined
                process to the development by instilling trust and ownership, so
                the development happens flawlessly. We start our training
                programs one year earlier before an employee becomes full time.
                This helps us to have employees 100% productive on day one. All these savings are
                passed on to our customers.
              </p>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-7 my-auto text-center text-md-start">
              <p className="subhead-saving-cost">3Wn3M Work culture</p>
              <img
                src="./assets/img/about_us/together.webp"
                className="img-fluid show_md_neards"
                width={400}
                alt="work culture" loading="crazy"
              />
              <p className="text-saving-cost">
                To have a highly engaged team, we have a work culture of 3Wn3M –
                3 Weeks in 3 Months. Each team can decide to go to any place –
                preferably remote exotic locations to get together for 3 weeks
                every 3 months, so they can work, play, eat and have time
                together. Highly informal but focused on clients’ projects and
                objectives of the company.
              </p>
            </div>
            <div className="col-md-5 my-auto">
              <img
                src="./assets/img/about_us/together.webp"
                className="img-fluid ms-lg-3 hide_md_neards"
                alt="work culture" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
