import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import validator from "validator";

import "./ContactUs.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

function Contact_us() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm - Contact us');

  const contact = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [query, setQuery] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [loading, setLoading] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      name: name,
      phone: phone,
      email: emailId,
      subject: subject,
      query: query,
    };
    setLoading("true");

    try {
      const data = await axios
        .post(
          `https://us-central1-unobiweb-v1.cloudfunctions.net/submitContactus`,
          postData
        )
        .then((res) => {
          console.log(res);
          alert("Submitted Successful");
        });

      setLoading("");
      setName("");
      setEmailId("");
      setPhone("");
      setSubject("");
      setQuery("");
      setEmailError("");
      setPhoneError("");
    } catch (error) {}

    // axios.post(`https://us-central1-unobiweb-v1.cloudfunctions.net/submitContactus`,postData).then(res=>{
    //     console.log(res)
    //     alert("Submitted Successful")
    //   }).catch((err) => console.log(err),console.log("error"))
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please provide valid Email.");
    }
  };

  const validatePhone = (e) => {
    var phone = e.target.value;

    if (validator.isMobilePhone(phone) && phone.length === 10) {
      setPhoneError("");
    } else {
      setPhoneError("Please provide valid Phone Number.");
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <section className="contact_us">
        <div className="container ">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 text-center text-lg-start my-auto">
              <p className="contactus_head">Contact Us</p>
              <p className="contactus_ts pt-2">
                Please Submit your Queries and messages we will reach out to you
                via mail or phone call
              </p>
              <img
                src="./assets/img/contact_us/contact_us.webp"
                className="img-fluid show_md"
                width={300}
                alt="flutterfarm contactus" loading="crazy"
              />
              <p>
                <button
                  type="button"
                  onClick={() => scrollToSection(contact)}
                  className="btn btn-lg query_btn_mod mt-1 mt-4 mt-lg-0 px-4"
                >
                  {" "}
                  Query Form <i className="fa-solid fa-angle-down ps-3"></i>{" "}
                </button>
              </p>
            </div>
            <div className="col-lg-5 text-end">
              <img
                src="./assets/img/contact_us/contact_us.webp"
                className="img-fluid hide_md_img"
                width={350}
                alt="flutterfarm contactus"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>

      <section
        ref={contact}
        className="py-5 mb-5"
        style={{ backgroundColor: "#F4F7FA" }}
      >
        <div className="container contact">
          <div className="row">
            <div className="col-12">
              <div className="card contact_card_mod px-lg-4 py-4">
                <div className="card-body">
                  <div className="row pb-3">
                    <div className="col-12 text-center">
                      <p className="touch_head">Get in touch !</p>
                      <p className="touch_subhead">
                        Here we are for you! How can we Help you?
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <p className="query_head">Query Form</p>
                      <hr className="hr_mod" />
                      {loading === "true" ? (
                        <div className="row">
                          <div className="col-12 text-center pt-5">
                            <div
                              className="spinner-grow text-center mx-auto"
                              style={{ width: "3rem", height: "3rem" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form className="row g-3 pt-3">
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control form_mod"
                              placeholder="Name"
                              id="name"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control form_mod"
                              id="phonenumber"
                              placeholder="Phone Number"
                              value={phone}
                              onChange={(e) => (
                                setPhone(e.target.value), validatePhone(e)
                              )}
                            />
                            <span
                              style={
                                phoneError !== ""
                                  ? {
                                      fontFamily: "raleway",
                                      fontWeight: "600",
                                      color: "#E11B2F",
                                      fontSize: "14px",
                                    }
                                  : {
                                      fontFamily: "raleway",
                                      fontWeight: "400",
                                      color: "#FFFFFF",
                                      fontSize: "14px",
                                    }
                              }
                            >
                              {phoneError}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="email"
                              className="form-control form_mod"
                              id="phonenumber"
                              placeholder="Email Id"
                              value={emailId}
                              onChange={(e) => (
                                setEmailId(e.target.value), validateEmail(e)
                              )}
                            />
                            <span
                              style={
                                emailError !== ""
                                  ? {
                                      fontFamily: "raleway",
                                      fontWeight: "600",
                                      color: "#E11B2F",
                                      fontSize: "14px",
                                    }
                                  : {
                                      fontFamily: "raleway",
                                      fontWeight: "400",
                                      color: "#FFFFFF",
                                      fontSize: "14px",
                                    }
                              }
                            >
                              {emailError}
                            </span>
                          </div>
                          <div className="col-12">
                            <input
                              type="text"
                              className="form-control form_mod"
                              id="inputAddress"
                              placeholder="Subject"
                              value={subject}
                              onChange={(e) => {
                                setSubject(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-control form_mod"
                              id="exampleFormControlTextarea1"
                              placeholder="Your Query"
                              rows="4"
                              value={query}
                              onChange={(e) => {
                                setQuery(e.target.value);
                              }}
                            ></textarea>
                          </div>
                          <div className="col-12 text-center pt-3">
                            <button
                              type="submit"
                              className="btn send_btn_mod px-3"
                              onClick={handleSubmit}
                              disabled={
                                name === "" ||
                                query === "" ||
                                phone === "" ||
                                emailId === "" ||
                                subject === "" ||
                                emailError !== "" ||
                                phoneError !== ""
                                  ? true
                                  : false
                              }
                            >
                              Send Message
                            </button>
                          </div>
                        </form>
                      )}
                    </div>

                    {/* <div className="col-lg-1"></div> */}
                    <div className="col-lg-5 ms-0 ms-lg-5 mt-5 mt-lg-0 text-center text-lg-start">
                      <p className="query_head">Contact Info</p>
                      <hr className="hr_mod" />

                      <div className="row mt-lg-4">
                        <div className="col-12">
                          <p className="location_head_ts">Locations</p>
                        </div>
                      </div>

                      <div className="row g-0">
                        <div className="col-sm-6">
                          <img
                            src="./assets/img/contact_us/taj-mahal.svg"
                            className="img-fluid"
                            width={35}
                            alt="india" loading="crazy"
                          />
                          <p className="location_head">India</p>
                          <p className="location_text">
                            FlutterFarm Private Limited, <br />
                            D11 - Provident Cosmo City, <br />
                            Pudupakkam, Chennai 603103, <br />
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <img
                            src="./assets/img/contact_us/usaa.svg"
                            className="img-fluid"
                            width={40}
                            alt="usa" loading="crazy"
                          />
                          <p className="location_head">USA</p>
                          <p className="location_text">
                          Aba techlabs LLC, <br />
                          Lake Orion, <br />
                          Michigan, USA. <br />
                          </p>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <p className="location_head_ts">Mail Us</p>
                          <p className="mail_text">
                            <img
                              src="./assets/img/contact_us/maill.svg"
                              className="img-fluid"
                              width={14}
                              alt="mail" loading="crazy"
                            />
                            <a
                              className="mailto ps-2"
                              href="mailto:support@flutterfarm.dev"
                              style={{ color: "black" }}
                            >
                              support@flutterfarm.dev
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Transform />
      <Footer />
    </React.Fragment>
  );
}

export default Contact_us;
