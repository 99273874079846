const PortfolioPageData =[
    {
        id:1,
        image:"/assets/img/portfolio/unobiapp.png",
        title:"App Development",
        description:"Unobi",
        category:"app",       
        params : "unobi",
        link : "mobile-app-development/unobi",
        banner : "/assets/img/portfolio/unobiapp_details.jpg",
        sitelink : "https://play.google.com/store/apps/details?id=com.unobi.app",
        content : "Owning a retail location with multiple employees and hundreds of customers every day is not an easy task. Unobi makes your life easier by providing access to every aspect of your retail location. Owner, Admin, Manager, Biller, Cashier, Purchaser, Checker – all in one app, and every one can manage their work centrally. Save Money. Grow Business."
    },
    {
        id:2,
        image:"/assets/img/portfolio/unobilabsapp.png",
        title:"App Development",
        description:"Unobilabs",
        category:"app",
        params : "unobilabs",        
        link : "mobile-app-development/unobilabs",       
        banner : "/assets/img/portfolio/unobilabsapp_details.jpg",
        sitelink : "https://unobilabs.com",
        content : "At Unobilabs, we believe in the power of accurate and timely diagnostics to improve healthcare outcomes. That's why we have made it our mission to provide a wide range of diagnostic tools and services to healthcare professionals and individuals around the world."        
    },
    {
        id:3,
        image:"/assets/img/portfolio/robotalksapp.png",
        title:"App Development",
        description:"Robotalks",
        category:"app",
        params : "robotalks",        
        link : "mobile-app-development/robotalks",
        banner : "/assets/img/portfolio/robotalksapp_details.jpg",
        sitelink : "https://play.google.com/store/apps/details?id=com.unobi.app",
        content : ""               
    },
    {
        id:4,
        image:"/assets/img/portfolio/unobilabsweb.png",
        title:"Web Development",
        description:"Unobilabs website",
        category:"website",
        params : "unobilabsweb",        
        link : "web-app-development/unobilabsweb",
        banner : "/assets/img/portfolio/unobilabsweb_details.jpg",
        sitelink : "https://unobilabs.com/",
        content : "At Unobilabs, we believe in the power of accurate and timely diagnostics to improve healthcare outcomes. That's why we have made it our mission to provide a wide range of diagnostic tools and services to healthcare professionals and individuals around the world."                              
    },
    {
        id:5,
        image:"/assets/img/portfolio/unobiweb.png",
        title:"Web Development",
        description:"Unobi website",
        category:"website",
        params : "unobiweb",        
        link : "web-app-development/unobiweb",
        banner : "/assets/img/portfolio/unobiweb_details.jpg",
        sitelink : "https://unobi.app/",
        content : "Owning a retail location with multiple employees and hundreds of customers every day is not an easy task. Unobi makes your life easier by providing access to every aspect of your retail location. Owner, Admin, Manager, Biller, Cashier, Purchaser, Checker – all in one app, and every one can manage their work centrally. Save Money. Grow Business."                              
    },
    {
        id:6,
        image:"/assets/img/portfolio/unobidesktop.png",
        title:"Desktop app Development",
        description:"Unobi store desktop",
        category:"desktop",
        params : "unobidesktop",        
        link : "desktop-app-development/unobidesktop",
        banner : "/assets/img/portfolio/unobidesktop_details.jpg",
        sitelink : "https://play.google.com/store/apps/details?id=com.unobi.app",
        content : "Owning a retail location with multiple employees and hundreds of customers every day is not an easy task. Unobi makes your life easier by providing access to every aspect of your retail location. Owner, Admin, Manager, Biller, Cashier, Purchaser, Checker – all in one app, and every one can manage their work centrally. Save Money. Grow Business."               
    },
    {
        id:7,
        image:"/assets/img/portfolio/unobidesktop1.png",
        title:"Desktop app Development",
        description:"Unobi distributor desktop",
        category:"desktop",
        params : "unobidesktop",        
        link : "desktop-app-development/unobidesktop",
        banner : "/assets/img/portfolio/unobidesktop1_details.jpg",
        sitelink : "https://play.google.com/store/apps/details?id=com.unobi.app",
        content : "Owning a retail location with multiple employees and hundreds of customers every day is not an easy task. Unobi makes your life easier by providing access to every aspect of your retail location. Owner, Admin, Manager, Biller, Cashier, Purchaser, Checker – all in one app, and every one can manage their work centrally. Save Money. Grow Business."               

    },
    {
        id:8,
        image:"/assets/img/portfolio/unobidesktop2.png",
        title:"Desktop app Development",
        description:"Unobi distributor desktops",
        category:"desktop",
        params : "unobidesktop",        
        link : "desktop-app-development/unobidesktop",
        banner : "/assets/img/portfolio/unobidesktop2_details.jpg",
        sitelink : "https://play.google.com/store/apps/details?id=com.unobi.app",
        content : "Owning a retail location with multiple employees and hundreds of customers every day is not an easy task. Unobi makes your life easier by providing access to every aspect of your retail location. Owner, Admin, Manager, Biller, Cashier, Purchaser, Checker – all in one app, and every one can manage their work centrally. Save Money. Grow Business."               

    },
    {
        id:9,
        image:"/assets/img/portfolio/eiteweb.png",
        title:"Web Development",
        description:"Elitequadrant website",
        category:"website",
        params : "elitequadrant",        
        link : "web-app-development/elitequadrant",
        banner : "/assets/img/portfolio/eiteweb_details.jpg",
        sitelink : "https://elitequadrant.com/",
        content : "Candidates don’t apply for a job, they apply for their career. Job descriptions are focused on what we want the candidates will do, but they miss what they would learn and grow in their career. Introducing EliteQuadrant. Bringing the candidates' career to the focus. Showcase what’s their winning."

    },    
    {
        id:10,
        image:"/assets/img/portfolio/flutterfarm_web_ui_1.png",
        title:"UI/UX",
        description:"Flutterfarm web UI",
        category:"UI/UX",
        params : "flutter-farm-web",        
        link : "ui-ux-designing/flutter-farm-web",
        banner : "/assets/img/portfolio/flutterfarm_web_ui_1_details.jpg",
        sitelink : "https://flutterfarm.dev/",
        content : "Do you have a SaaS idea that needs a faster prototype? We are here to bring your ideas and dreams to reality. At FlutterFarm, we have well trained team with expertise in Flutter, a Google Framework to build solutions for mobile, web and desktop with the same code base. Get the advantage from Scope Triangle – Cost, Time and Quality. Being offshored to India, the cost of the development is multi-fold reduced."

    },    
    {
        id:11,
        image:"/assets/img/portfolio/flutterfarm_web_ui_2.png",
        title:"UI/UX",
        description:"Flutterfarm website UI",
        category:"UI/UX",
        params : "flutter-farm-website",        
        link : "ui-ux-designing/flutter-farm-website",
        banner : "/assets/img/portfolio/flutterfarm_web_ui_2_details.jpg",
        sitelink : "https://flutterfarm.dev/",
        content : "Do you have a SaaS idea that needs a faster prototype? We are here to bring your ideas and dreams to reality. At FlutterFarm, we have well trained team with expertise in Flutter, a Google Framework to build solutions for mobile, web and desktop with the same code base. Get the advantage from Scope Triangle – Cost, Time and Quality. Being offshored to India, the cost of the development is multi-fold reduced."
    },    
    {
        id:17,
        image:"/assets/img/portfolio/unobiapp_ui.png",
        title:"UI/UX",
        description:"Unobi App UI",
        category:"UI/UX",
        params : "unobi-app",        
        link : "ui-ux-designing/unobi-app",
        banner : "/assets/img/portfolio/unobiapp_ui_details.jpg",
        sitelink : "https://play.google.com/store/apps/details?id=com.unobi.app",
        content : "Owning a retail location with multiple employees and hundreds of customers every day is not an easy task. Unobi makes your life easier by providing access to every aspect of your retail location. Owner, Admin, Manager, Biller, Cashier, Purchaser, Checker – all in one app, and every one can manage their work centrally. Save Money. Grow Business."
    },                                 
    {
        id:18,
        image:"/assets/img/portfolio/posterdesign.png",
        title:"Graphics Design",
        description:"Poster design Flutterfarm",
        category:"Graphics Design",
        params : "poster-design-flutterfarm",        
        link : "graphic-designing/poster-design-flutterfarm",
        banner : "/assets/img/portfolio/posterdesign_details.png",
        sitelink : "https://flutterfarm.dev",
        content : "Do you have a SaaS idea that needs a faster prototype? We are here to bring your ideas and dreams to reality. At FlutterFarm, we have well trained team with expertise in Flutter, a Google Framework to build solutions for mobile, web and desktop with the same code base. Get the advantage from Scope Triangle – Cost, Time and Quality. Being offshored to India, the cost of the development is multi-fold reduced."
    },
    {
        id:18,
        image:"/assets/img/portfolio/robotalksposter.png",
        title:"Graphics Design",
        description:"Poster design Robotalks",
        category:"Graphics Design",
        params : "poster-design-robotalks",        
        link : "graphic-designing/poster-design-robotalks",
        banner : "/assets/img/portfolio/robotalksposter_details.jpg",
        sitelink : "",
        content : ""
    }                                                                              
]

export default PortfolioPageData;