import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Home.css";

// Components Impoert
import Estimate from "../../components/estimate/Estimate";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";

// Json Import
import { industry_exp } from "../../Data/HomePageData";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const Home = () => {
  useDocumentTitle('FlutterFarm - Custom Software Development Solutions for Mobile, Web and Desktop Application.');  
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <React.Fragment>
      <Navbar />

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 text-center text-lg-start my-auto ps-md-3 ps-lg-0">
              <p className="disp_sm_hide_hero">
                <span className="hero-head_ts">You dream it.</span>
                <br />
                <span className="hero-subhead_ts">We build it.</span>
              </p>
              <p className="disp_sm_show_hero">
                <span className="hero-head_ts">You dream it.</span>
                <span className="hero-subhead_ts"> We build it.</span>
              </p>
              <img
                src="./assets/img/home_page/51410978.webp"
                className="img-fluid disp_sm_show_hero p-4"
                alt="flutterfarm home page" loading="crazy"
              />
              <p className="hero-text_ts pb-1 my-4">
                Do you have a SaaS idea that needs a faster prototype? We are
                here to bring your ideas and dreams to reality. At FlutterFarm,
                we have well trained team with expertise in <span className="highlight">Flutter, a Google
                Framework to build solutions for mobile, web and desktop with
                the same code base.
                  </span>  Get the advantage from Scope Triangle –
                Cost, Time and Quality. Being offshored to India, the cost of
                the development is multi-fold reduced. Get the advantage of the
                time zone, and your dreams becomes reality when you wake up! Our
                team uses all modern tools and technologies for code maintenance
                and deployment, including GitHub, Jira and Slack.
              </p>
              <Estimate
                btn="btn btn-lg"
                estimate="Why think? Just Estimate!"
                icon="fa-solid fa-right-long ps-3 py-2"
              />
            </div>
            <div className="col-lg-5 my-auto text-end">
              <img
                src="./assets/img/home_page/51410978.webp"
                className="img-fluid disp_sm_hide_hero hero_img_mod"
                alt="flutterfarm home page" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="clients">
        <div className="container pt-4">
          <p className="text-center clients_ts">Some of our client's SaaS products</p>
          <div className="row pt-4 pb-5 ">
            <div className="col-4">
              <img
                src="./assets/img/home_page/unobi_logo.svg"
                className="img-fluid opacity"
                width={100}
                alt="unobi" loading="crazy"
              />
            </div>
            <div className="col-4 text-center">
              <img
                src="./assets/img/home_page/musicmeet.svg"
                className="img-fluid opacity"
                width={200}
                alt="musicmeets" loading="crazy"
              />
            </div>
            <div className="col-4 text-end">
              <img
                src="./assets/img/home_page/robotalks.svg"
                className="img-fluid opacity"
                width={170}
                alt="robotalks" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="industry-exp py-5">
        <div className="container">
          <p className="text-center industry-exp_head">Industry experience</p>
          <div className="row pb-5 mx-auto">
            {industry_exp.map((e) => (
              <div className="col-6 col-md-4 col-lg-3 text-center pt-5">
                <img
                  src={e.img_url}
                  key={e.id}
                  className="img-fluid"
                  style={{ height: "40px" }}
                  alt={e.img_head} loading="crazy"
                />
                <p className="industry-exp_ts pt-3">{e.img_head}</p>
              </div>
            ))}
          </div>
          <div className="text-center">
            <Estimate
              btn="btn btn-lg px-4"
              estimate="Estimate Project"
              icon="fa-solid fa-right-long ps-3 py-2"
            />
          </div>
        </div>
      </section>

      <section className="why-flutterfarm mb-5">
        <div className="container-fluid container-lg py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="why-flutterfarm_head why_text_hide_top">
                Why choose FlutterFarm ?
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-5 col-lg-4 my-auto text-lg-center">
              <img
                src="./assets/img/home_page/5883082.webp"
                className="img-fluid hide_WhyFlutterFarm"
                alt="Why Choose FlutterFarm" loading="crazy"
              />
            </div>
            <div className="col-md-7 col-lg-8 my-auto">
              <p className="why-flutterfarm_head why_text_hide text-center text-md-start">
                Why choose FlutterFarm ?
              </p>
              <p className="why-flutterfarm_ts pt-2">
                <div className="row pt-3">
                  <div className="col-2 col-md-1 text-end">
                    <img
                      src="./assets/img/home_page/multiplatform.svg"
                      width={30}
                      className="img-fluid"
                      alt="multi-platform" loading="crazy"
                    />
                  </div>
                  <div className="col-10 col-md-11 my-auto">
                    <p>
                      One Affordable solution for multi-platform deployment.
                    </p>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-2 col-md-1  text-end">
                    <img
                      src="./assets/img/home_page/estimate.svg"
                      width={30}
                      className="img-fluid"
                      alt="talent sourcing" loading="crazy"
                    />
                  </div>
                  <div className="col-10 col-md-11 my-auto mt-1">
                    <p>
                      Flexible options from outsoucing a project to talent
                      sourcing choose what works for you.
                    </p>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-2 col-md-1 text-end">
                    <img
                      src="./assets/img/home_page/resources.svg"
                      width={30}
                      className="img-fluid"
                      alt="resources" loading="crazy"
                    />
                  </div>
                  <div className="col-10 col-md-11 mt-1">
                    <span>
                      Scale up or down the resources at any time. No string
                      attached.
                    </span>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-2 col-md-1 text-end">
                    <img
                      src="./assets/img/home_page/estimate.svg"
                      width={30}
                      className="img-fluid"
                      alt="estimate" loading="crazy"
                    />
                  </div>
                  <div className="col-10 col-md-11 mt-1">
                    <span>
                      Receive estimate online for small jobs and within 24 hours
                      for bigger projects.
                    </span>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='mb-5'>
            <div className="container-fluid container-lg px-5 px-lg-0 my-5">
                <div className="row pb-5 text-center">
                    <div className="col-12">
                        <p className='industry-exp_head'>Our Works</p>
                        <p className='industry-exp_subhead mb-4'>Bring your ideas to life through great expertise and consultations</p>
                    </div>
                </div>            

                <div id="carouselExampleControlsNoTouching" className="carousel carousel_height slide" data-bs-touch="false" data-bs-interval="false" >
                <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className="row">
                    <div className="col-12 col-md-8 text-center text-md-start">
                            <img src="./assets/img/home_page/unobi_logo_color.svg" className='img-fluid' width={120} alt="Unobi-logo" />
                            <p className='carousel_head pt-2'>Smart Bussiness Solution</p>
                            <img src="./assets/img/home_page/unobi_works.webp" className='img-fluid disp_sm_show' width={300} alt="Unobi" /> 
                            <p className='carousel_text pt-2'>Cloud-based, end-to-end Uber-like Cab Aggregation Platform. Fast Track is one of the best taxi service providers in India. They provide both out-station taxi booking and local cab services. They operate a large network with offices in all major cities Cloud-based, end-to-end Uber-like Cab Aggregation Platform. Fast Track is one of the best taxi service providers in India. They provide both out-station taxi booking and local cab services. They operate a large network with offices in all major cities </p>
                            <button type='button' className='btn btn-lg case_study_btn text-white py-3 px-4 mt-3'>Case Study <i className="fa-solid fa-right-long ps-3"></i></button>
                    </div>
                    <div className="col-md-4 my-auto text-end">
                        <img src="./assets/img/home_page/unobi_works.webp" className='img-fluid disp_sm_hide' width={300} alt="Unobi" />  
                    </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="row">
                    <div className="col-8">
                    <div className="p-3 border bg-light">Cloud-based, end-to-end Uber-like Cab Aggregation Platform. Fast Track is one of the best taxi service providers in India. They provide both out-station taxi booking and local cab services. They operate a large network with offices in all major cities Cloud-based, end-to-end Uber-like Cab Aggregation Platform. Fast Track is one of the best taxi service pr</div>
                    </div>
                    <div className="col-4">
                        <div className="p-3 border bg-light">Custom column padding Custom column paddingCustom column paddingCustom column padding</div>
                    </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="row">
                    <div className="col-8">
                    <div className="p-3 border bg-light">Cloud-based, end-to-end Uber-like Cab Aggregation Platform. Fast Track is one of the best taxi service providers in India. T</div>
                    </div>
                    <div className="col-4">
                        <div className="p-3 border bg-light">Custom column padding Custom column paddingCustom column paddingCustom column padding</div>
                    </div>
                    </div>
                </div>
                </div>               
            </div>

            <div className="row pt-5">
                <div className="col-12 text-center">
                <div className="control-mod">
                <button className="controller_mod" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                    <img src="./assets/img/home_page/left_icon.svg" className='img-fluid' width={60} alt="Previous" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="controller_mod ms-4" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                    <img src="./assets/img/home_page/right_icon.svg" className='img-fluid' width={60} alt="Next" />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
                </div>
            </div>

            </div>
        </section>       */}

      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
