import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./Support.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const Support = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – support');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Support</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 my-auto text-center">
              <img
                src="/assets/img/services/support.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/support.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              We guarantee you a reply within 24 hours, whether it’s a sales call or technical call. When we do our projects, we will have dedicated support and daily updates comes to your email. Check us out by offering small projects.
              </p>
            </div>
          </div>
        </div>
      </section>

   
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default Support;
