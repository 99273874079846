import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./MobileAppDevelopment.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const MobileAppDevelopment = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – mobile app development');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Mobile App Development</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/app_development.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              The mobile app development process is the process of creating a mobile application for a specific mobile operating system. Mobile app development can be done using a variety of programming languages and tools. 
The first step in mobile app development is to ideate or come up with an idea for the app. This can be done by brainstorming with friends, family, or colleagues. Once an idea has been decided upon, the next step is to wireframe or create a prototype of the app. This helps to visualize how the app will look and function.

After the wireframe is complete, the next step is to start coding the app. This can be done using a variety of programming languages and tools. Once the code is complete, the next step is to test the app. This can be done by running the app on a simulator or device. After the app has been tested, the next step is to deploy the app. This involves packaging the app and uploading it to a store such as the App Store or Google Play.

              </p>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/app_development.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

      <center style={{"padding-top" : "2rem"}}><h3 >
      The Mockups of our App development:
</h3></center>

<div className="container01">
<center><img src="/assets/img/flutterfarm_app_1.png" style={{"width":"60%"}} /></center>

<center><img src="/assets/img/flutterfarm_app_2.png" style={{"width":"60%"}} /></center>

<center><img src="/assets/img/flutterfarm_app_3.png" style={{"width":"196px","height":"254px"}} /></center>
</div>
 
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default MobileAppDevelopment;
