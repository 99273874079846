import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./DesktopAppDevelopment.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const DesktopAppDevelopment = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – desktop app development');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Desktop App Development</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/desktop_app_development.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />
              <p className="nerds_text pt-2">
              Desktop app development is the process of creating software for a desktop or laptop computer. This can be done using a variety of programming languages and tools, depending on the platform you're targeting and the features you want to include in your app. 
</p>
<p className="nerds_text pt-2">
If you're just starting out, you might want to consider using a cross-platform tool like Electron. This will allow you to code your app in HTML, CSS, and JavaScript, and then package it for use on Windows, macOS, or Linux. 
</p>
<p className="nerds_text pt-2">
If you're targeting a specific platform like Windows, you'll need to use the Windows API and language tools like C# or Visual Basic. For macOS, you'll need to use Objective-C or Swift. And for Linux, you can use any number of languages and tools, depending on your distribution. 
</p>
<p className="nerds_text pt-2">
No matter what platform you're targeting, there are a few things to keep in mind when designing your app. First, your app should have a clear and concise purpose. It should be easy to use and understand, with a minimalistic design. Second, your app should be able to run without internet connection. This means that it should be able to function offline, and should be able to sync data when a connection is available. Finally, your app should be secure, with data encryption and user authentication. 

              </p>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/desktop_app_development.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>
      <center style={{"padding-top" : "2rem"}}><h3 >
The Mockups of our Desktop development:
</h3></center>

<div className="container01">
 

<center><img src="/assets/img/flutterfarm_desktop_unobi.png" style={{"height":"350px"}} /></center>

<center><img src="/assets/img/flutterfarm_desktop_unobi_2.png" style={{"height":"311px","padding-top":"58px"}} /></center>

<center><img src="/assets/img/flutterfarm_desktop_unobi.png" style={{"height":"350px"}} /></center>
</div>
    
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default DesktopAppDevelopment;
