import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./Maintenance.css";
import Navbar from "../../components/navbar/Navbar";
import Transform from "../../components/transform/Transform";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from '../../useDocumentTitle'

const Maintenance = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useDocumentTitle('FlutterFarm – maintanance');

  return (
    <React.Fragment>
      <Navbar />
      <section className="About_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <p className="aboutus_head">Maintenance</p>
              <p className="aboutus_subhead">
                Our vision is to bring your dreams to reality at the fastest
                time possible with highest customer focus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row hide_nerd_head">
            <div className="col-12 text-center">
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 my-auto">
              <img
                src="/assets/img/services/maintanance.webp"
                className="img-fluid show_md_neards"
                alt="flutterfarm" loading="crazy"
              />

              <p className="nerds_text pt-2">
We have a team of highly qualified, IT certified and skilled professionals that handles your project at each step. We provide you with a detailed documentation of your project and provide you with the tools to view your project progress. 
</p>              <p className="nerds_text pt-2">
We provide you with a complete host of maintenance services that will allow you to concentrate on your core business.
</p>              <p className="nerds_text pt-2">
It is always best to have someone on hand to take care of your online business maintenance services needs. This can be a very daunting task for someone who is new to online business, but it is well worth it in the end.
</p>              <p className="nerds_text pt-2">
There are many things that need to be kept up with, such as website design, content management, and even customer service. By having someone on hand to handle all of these tasks, you can focus on other aspects of your business and not have to worry about the little things.

              </p>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/img/services/maintanance.webp"
                className="img-fluid hide_md_neards"
                width={425}
                alt="flutterfarm" loading="crazy"
              />
            </div>
          </div>
        </div>
      </section>

   
      <Transform />
      <Footer />
    </React.Fragment>
  );
};

export default Maintenance;
