import { v4 as uuidv4 } from 'uuid';

export let client = [
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/unobi_logo.svg"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/robotalks.svg"

    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/unobi_logo.svg"
    }
]

export let industry_exp = [
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/isv_product_companies.svg",
        img_head: "ISVs Product & Companies"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/digital_marketing.svg",
        img_head: "Digital & Marketing Agencies"

    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/healthcare.svg",
        img_head: "Healthcare"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/retail_e-commerce.svg",
        img_head: "Retail & E-Commerce"
    },
    
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/education_e-learning.svg",
        img_head: "Education & E-Learning"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/automotive.svg",
        img_head: "Automotive"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/logistics_transportation.svg",
        img_head: "Logistics & Transportation"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/media_entertainment.svg",
        img_head: "Media & Entertainment"
    },
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/technology.svg",
        img_head: "Technology"
    },
    
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/travel_tourism.svg",
        img_head: "Travel & Tourism"
    },
    
    {
        id: uuidv4(),
        img_url : "./assets/img/home_page/banking_finance.svg",
        img_head: "Banking & Finance"
    }
]