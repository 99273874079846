import React from "react";
import "./Navbar.css";

import { NavLink, Link } from "react-router-dom";

import Estimate from "../estimate/Estimate";

function Navbar() {
  const navLinkStyle = (isActive) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
      textDecoration: isActive ? "none" : "underline",
    };
  };
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg sticky-top bg-white navbar-light py-3 navbar_mod">
        <div className="container-fluid container-lg">
          <NavLink to="/" className="navbar-brand">
            <img src="/assets/img/Logo.svg" className="img-fluid" width="160" alt="" />
          </NavLink>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            {/* <Link to="/home" className="navbar-brand hide-lg">
                        <img src="./assets/img/logo/logo6.svg" className='img-fluid' alt="" />
                    </Link> */}

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item px-2">
                <NavLink to="/home" style={navLinkStyle} className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/services"
                  style={navLinkStyle}
                  className="nav-link"
                >
                  What we provide
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/aboutus"
                  style={navLinkStyle}
                  className="nav-link"
                >
                  About
                </NavLink>
              </li>
              {/* <li className="nav-item px-2 pe-3">
                        <a className="nav-link" href="https://flutterfarm.dev">Blog</a>
                        </li> */}
              <li className="nav-item px-2 pe-3">
                <NavLink
                  to="/contactus"
                  style={navLinkStyle}
                  className="nav-link"
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item px-2 pe-3">
                <NavLink
                  to="/portfolio"
                  style={navLinkStyle}
                  className="nav-link"
                >
                  Portfolio
                </NavLink>
              </li>              
              <li className="nav-item px-2 pe-3">
                <NavLink
                  to="/blogs"
                  style={navLinkStyle}
                  className="nav-link"
                >
                  Blog
                </NavLink>
              </li>              
              <Estimate
                estimate="Estimate"
                btn="btn btn px-3 estimate_btn"
                icon="fa-solid fa-right-long ps-2"
              />
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Navbar;
